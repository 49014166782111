import { useLocation } from "react-router-dom";
import FAQ from "../FAQ/FAQ";
import { CART_ROUTE } from "../../consts";

export default function OrderInfo({ text }) {

    const location = useLocation().pathname

    return (
        location !== CART_ROUTE &&
        <FAQ isWrapClickable={false} title="Order Info" text={text} className="mb" />
    )
}
