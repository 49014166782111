export default function calcCartProductOptionsAdditionalPrice(cartProdCustomFields) {
    let allAdditionalPrices = 0

    cartProdCustomFields?.map(({ value }) => {
        let additionalPrice
        if (typeof value === "object") { // { name: 'red', price: '10' }
            additionalPrice = value.price
        } else {
            additionalPrice = 0
        }
        allAdditionalPrices += Number(additionalPrice)
    })

    return allAdditionalPrices
}
