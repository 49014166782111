import { useContext } from "react"
import { Context } from "../Context"
import { PROFILE_ROUTE } from "../consts"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

export default function useNoUser() {

	const { user } = useContext(Context)
	const navigate = useNavigate()
	
	const [isUser, isUserSet] = useState(false)
	useEffect(() => {
		isUserSet(user) // no user(null) || {}
	}, [user])

	function noUserRedirect() {
		if (isUser === false) return // round 1: useEffect NOT fired => return
		// round 2: useEffect fired => no user => navigate
		isUser === null && (navigate(PROFILE_ROUTE))
	}

	return (
		{ noUserRedirect }
	)
}
