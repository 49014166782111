import React from "react"
import { Search } from "@mui/icons-material"
import SearchField from "./SearchField"
import { useState } from "react"

export default function SearchIcon({ className }) {

	const [isSearchFieldHovered, isSearchFieldHoveredSet] = useState(false)

	return (
		<div className={`f fwn aic por wid330 ${className ? className : ""}`}>
			<SearchField isSearchFieldHoveredSet={isSearchFieldHoveredSet} />
			{!isSearchFieldHovered && <Search className="poa r5 t2" />}
		</div>
	)
}
