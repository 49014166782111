import React, { useState, useRef, useEffect } from "react";
import Input from "../form/Input"

export default function ShippingAddressInput({ cityValueSet, stateValueSet, zipCodeValueSet, countryValueSet, editValue, isInputDisabled, addressErrorSet, shippingPriceSet }) {

    const inputRef = useRef(null);

    const [value, valueSet] = useState(editValue)
    useEffect(() => {
        editValue && valueSet(editValue)
    }, [editValue])

    useEffect(() => {
        const loadGoogleScript = () => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            // !!! TODO google api move to .env
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBQ4sRrokMIs-YjiOw12oaI-45mYPLaFN4&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = initializeAutocomplete;
            document.head.appendChild(script);
        };

        const initializeAutocomplete = () => {
            const autocomplete = new window.google.maps.places.Autocomplete(
                inputRef.current
            );

            // ! `onChange`
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                let city, state, zip, country, streetNumber, streetName
                place?.address_components?.map((place) => {
                    place.types[0] === "street_number" && (streetNumber = place.long_name)
                    place.types[0] === "route" && (streetName = place.long_name)
                    place.types[0] === "locality" && (city = place.long_name)
                    place.types[0] === "administrative_area_level_1" && (state = place.short_name)
                    place.types[0] === "country" && (country = place.long_name)
                    place.types[0] === "postal_code" && (zip = place.long_name)
                });

                !streetNumber ? addressErrorSet({ streetNumber: true }) : addressErrorSet({ streetNumber: false })

                cityValueSet("") // null city to ensure if no city found it will be empty so user can clarify city
                cityValueSet(city)

                stateValueSet(state)
                countryValueSet(country)
                zipCodeValueSet(zip)
                valueSet(`${streetNumber || ""} ${streetName || ""}, ${city || ""}, ${state || ""}, ${zip || ""}`)
                shippingPriceSet(0)
            });
        };

        if (window.google) {
            initializeAutocomplete();
        } else {
            loadGoogleScript();
        }
    }, []);

    function clearInputs() {
        // ! rarely google autocomplete gives data without ZIP, though it's written inside input
        cityValueSet("")
        stateValueSet("")
        countryValueSet("")
        zipCodeValueSet("")
        valueSet("")
        shippingPriceSet(0)
    }

    return (
        <div>
            <Input
                required
                name="address"
                editValue={value}
                inputRef={inputRef}
                type="text"
                placeholder="address"
                label="address"
                helperText="e.g.: 777 Brockton Avenue, Abington, MA, 2351"
                className="my2 muiHelperTextAbsolute"
                onChange={clearInputs}
                isDisabled={isInputDisabled}
                autoComplete="off"
            />
        </div>
    );
}
