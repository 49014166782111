import React from "react"
import { useLocation } from "react-router-dom"
import { BRAND_COLOR, CART_ROUTE } from "../../consts"
import { UnarchiveOutlined } from "@mui/icons-material"
import { CloudDownload } from "@mui/icons-material"
import "./index.scss"

export default function CartShipmentCost({ userOrOrder }) {

	const location = useLocation().pathname
	const shipmentPrice = userOrOrder?.shipment?.price?.toFixed(2)

	return (
		location !== CART_ROUTE &&
		<section className="cartShipmentCost cardAnim">
			{userOrOrder?.shipment?.shipmentType === "normal"
				?
				<UnarchiveOutlined className="normalShipmentIcon" style={{ height: 60, width: 60, marginLeft: 10 }} />
				:
				<CloudDownload style={{ height: 60, width: 60, marginLeft: 10, fill: BRAND_COLOR, pointerEvents: "none" }} />
			}
			<div className="fc">
				{userOrOrder?.shipment?.shipmentType === "normal" &&
					<div className="title3">SHIPMENT</div>
				}
				<div>{userOrOrder?.shipment?.title}</div>
			</div>
			{userOrOrder?.shipment?.shipmentType !== "digital" &&
				<div className="title3 mla">${shipmentPrice}</div>
			}
		</section>
	)
}
