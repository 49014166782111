import { useContext } from "react"
import { Context } from "../../Context"

export default function ForUserRole({ children, role }) {

	const { user } = useContext(Context)

	return (
		<>
			<>{role === "admin" && user?.isAdmin && children}</>
			<>{role === "user" && !user?.isAdmin && children}</>
		</>
	)
}
