export default function parseFormAddToCart(e) { // e=event from onSubmit (html form)

	const temp_customs_field_names = []
	const custom_fields = []

	// ! gather temp_customs_field_names ['color', 'text', 'url', 'number', 'file']
	e.target.querySelectorAll("input, select, textarea").forEach(each => {
		if (each.name.includes("custom_")) {
			temp_customs_field_names.push(each.value)
		}
	})

	// ! make form
	let form = {} // {"any field 1": "@","any field 2": "123","any field 3": "abc"}

	e.target.querySelectorAll("input, select, textarea").forEach(each => {

		if (each.name && each.value) {
			temp_customs_field_names.map(cfName => {
				if (cfName === each.name) {
					const name = each.name
					const value = !each.value.includes("{") ? each.value : JSON.parse(each.value)
					const type = each.type
					custom_fields.push({ name, value, type })
				}
			})

			// ! when done types in DB can remove this
			if (each.name.includes("custom_")) return
			if (temp_customs_field_names.includes(each.name)) return

			form = { ...form, custom_fields, [each.name]: each.checked || each.value }
		}
	})

	return (
		{ form }
	)
}