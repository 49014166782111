import React, { useContext, useState, useEffect } from "react"
import "./index.scss"
import { Context } from "../../Context"
import { Close } from '@mui/icons-material';
import { useLocation, useNavigate } from "react-router-dom"
import { CART_ROUTE } from "../../consts"
import * as api from "../../api"
import calcCartProductOptionsAdditionalPrice from "./calcCartProductOptionsAdditionalPrice";

export default function CartReminder() {

	const { user, products, updateContext, shippingPrice } = useContext(Context)

	useEffect(() => {
		async function getActualUserCart() {
			await api.getActualUserCart()
			updateContext()
		}

		getActualUserCart()
	}, [])

	// totalPrice
	const [totalPrice, totalPriceSet] = useState(0)

	// change price style on totalPrice change
	useEffect(() => {
		document.querySelector(".cartReminderPrice")?.classList.remove("cardAnim")
		document.querySelector(".cartReminderPrice")?.classList.remove("brand")
		setTimeout(() => {
			document.querySelector(".cartReminderPrice")?.classList.add("cardAnim")
			document.querySelector(".cartReminderPrice")?.classList.add("brand")
		}, 1);
	}, [totalPrice])

	const [show, showSet] = useState(true)
	const navigate = useNavigate()

	function close(e) {
		e.stopPropagation()
		showSet(prev => !prev)
	}

	// calculations
	let allProductsTotalPrice = 0
	let quantity = 0

	user?.cart?.map(cartProd => { // map cart
		return products?.map(allProd => { // map all products
			if (allProd.status === "hidden" || allProd.status === "deleted") return // prevent adding "hidden/deleted" prods to CartReminder
			if (cartProd._id === allProd._id) {
				quantity += Number(cartProd.quantity)
				const allAdditionalPrices = calcCartProductOptionsAdditionalPrice(cartProd?.custom_fields)
				const totalPrice = cartProd?.quantity * allProd.price
				allProductsTotalPrice += totalPrice + (allAdditionalPrices * cartProd?.quantity)
			}
		})
	})

	// ! include shippingPrice to CardReminder
	allProductsTotalPrice += Number(shippingPrice)

	// totalPrice
	useEffect(() => {
		totalPriceSet(allProductsTotalPrice.toFixed(2))
	}, [allProductsTotalPrice])

	function onClick() {
		navigate(CART_ROUTE)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const location = useLocation().pathname

	return (
		location !== CART_ROUTE &&
		show && quantity > 0 &&
		<div className="cartReminder blurAnim" onClick={onClick}>
			{!shippingPrice
				?
				<div>{quantity} product{quantity > 1 && "s"} in cart: &nbsp;</div>
				:
				<div>Total (with shipping): &nbsp;</div>
			}
			<div className="cartReminderPrice cardAnim brandi">${totalPrice}</div>
			<Close onClick={close} className="ml" />
		</div>
	)
}
