export default [
	// HOW TO USE:
	/* 1. local files: 
		- must be placed in "./img" ("img" folder in this folder)
		- "src" must equal image name
	*/
	/* 2. web files: 
		- "src" = link to image 
	*/
	{ title: "Models", src: "models.jpg" },
	{ title: "Plans", src: "plan.jpg" },
]
