import { Skeleton } from '@mui/material'

export default function Product_article_card_img_img({ isImgLoaded, _img, isImgHoveredSet, postType, className }) {

    function switchFromMainImgToChangeable() {
        if (postType === "article") return
        isImgHoveredSet(true)
    }

    return (
        <>
            {isImgLoaded ? <img className={className} src={_img} onMouseEnter={switchFromMainImgToChangeable} /> : <Skeleton variant="rectangular" height={224} width={330} />}
        </>
    )
}
