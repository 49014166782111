import React from "react"
import useCopyToClipBoard from "../../hooks/useCopyToClipBoard"

// eg: custom text/color/size/...
export default function CartCardCustomFields({ obj }) {

	const { copyToClipBoard } = useCopyToClipBoard()
	const openLink = (link) => window.open(link)

	return (
		<div className="maw100 oh toe wsn">
			{/* eg: 												custom text || color                                  // !! if obj => parse name                                                 show if product includes additional price*/}
			{obj?.custom_fields?.map(({ name, value, type }, ind) => {
				const fieldValue = value || "not selected"
				const displayFieldValue = type === "file" ? obj?.file?.match(/[^/]+$/) : fieldValue // display short server link to img (if field = file) / or fieldValue
				return (
					<div key={ind}>
						<span>{name}</span>:&nbsp;
						<b
							onClick={(e) => type === "file" ? openLink(obj.file) : copyToClipBoard(e)}
							style={{ display: "inline-block", width: 10 }} // prevent very wide card
							className={type === "file" ? "curAlias" : "curCopy"}
						>
							<div className="f g05 fwn">
								<div>{typeof fieldValue === "object" ? value.name : displayFieldValue} </div>
								<div>{typeof fieldValue === "object" && "+$" + fieldValue.price}</div>
								<div className="desktop">{typeof fieldValue === "object" && `+${fieldValue.weight}g`}</div>
								<div>{typeof fieldValue === "object" && " each"}</div>
							</div>
						</b>
					</div>
				)
			})}
		</div>
	)
}
