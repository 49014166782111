export default function digitalDescOrNormal(form) {

    let digitalDesc

    if (form.isDigitalSaleOnly && !form.desc.includes("creativecommons")) {
        digitalDesc = form.desc + `\n\n**This design is protected by Creative Commons (4.0 International License)** \n\nAttribution—Noncommercial—No Derivatives \n✖ | Sharing without ATTRIBUTION \n✖ | Remix Culture allowed \n✖ | Commercial Use \n✖ | Free Cultural Works \n✖ | Meets Open Definition \n\nYou may not produce, reproduce, modify, or sell this product or it's design without express permission from Sendt Designs. \n\nhttps://creativecommons.org/licenses/by-nc-nd/4.0/ `
    }

    return !digitalDesc ? form.desc : digitalDesc
}
