import React from 'react'
import IsInCartIcon from '../product/IsInCartIcon'

export default function Product_card_bottom({ obj }) {

    const { price } = obj

    return (
        <>
            <div>${price}</div>
            <IsInCartIcon obj={obj} />
        </>
    )
}
