import "./index.scss"
import { MAIN_ROUTE } from "../../consts"
import logoMain from "../../img/logoMain.png"
import HeaderMenu from "./HeaderMenu"
import HeaderIcon from "./HeaderIcon"
import useIsNotOnTop from "../../hooks/useIsNotOnTop"
import HeaderIcons from "./HeaderIcons"

export default function Header() {

	const { isNotOnTop } = useIsNotOnTop()

	return (
		<>
			<header className={`pof f jcsb bfb ${isNotOnTop ? "op05" : ""}`}>

				<HeaderIcon route="products?text=&sort=createdAt%26desc">
					<div className="fcc fz45">
						<img src={logoMain} className="ml logoMain" />
						<span className="white ffFuggles">sendt-3d</span>
					</div>
				</HeaderIcon>

				<HeaderIcons className="desktop pof r100" />

				<HeaderMenu />

			</header>
			{/* header is fixed, so need this trick div (adds margin-bottom) */}
			<div className="mb4 op0">"needs content"</div>
		</>
	)
}