import { Button } from "@mui/material"
import React, { useContext, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { ARTICLES_ROUTE, LIKED_PRODS_ROUTE, PRODUCTS_ROUTE } from "../../consts"
import { Context } from "../../Context"
import SpinnerWithoutChildren from "../other/SpinnerWithoutChildren"

// eg:			SKIP LIMIT
// round 1		 0    10
// round 2		10		10
// round 3		20    10
// round 4		30    10
// ...
export default function LoadMore({ skips, serverPostsNum, status, loadings }) {

	// ! define which skipSet to use
	const location = useLocation().pathname
	let skipSet
	let loading
	// case 1
	if (location === LIKED_PRODS_ROUTE) {
		skipSet = skips.skipSet1
		serverPostsNum = serverPostsNum.serverPostsNum1
		loading = loadings.loading1
	}
	// case 2
	if (location === PRODUCTS_ROUTE || location === ARTICLES_ROUTE) {
		skipSet = skips.skipSet2
		serverPostsNum = serverPostsNum.serverPostsNum2 // for prods only: from Context
		loading = loadings.loading2
	}
	// case 5
	if (location.includes("/hidden/")) {
		skipSet = skips.skipSet5
		serverPostsNum = serverPostsNum.serverPostsNum5
		loading = loadings.loading5
	}
	// ? define which skipSet to use

	// ! click load more if reached the bottom of the window
	const loadMoreBtnRef = useRef(null)
	useEffect(() => {
		const handleScroll = () => {
			const windowHeight = window.innerHeight;
			const documentHeight = document.documentElement.scrollHeight;
			const scrollTop = window.scrollY;

			// Check if the user has reached the bottom of the window (-5px)
			if (windowHeight + scrollTop + 5 >= documentHeight) {
				loadMoreBtnRef?.current?.click();
			}
		};

		// Attach the scroll event listener when the component mounts
		window.addEventListener('scroll', handleScroll);

		// Remove the event listener when the component unmounts
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	//  ? click load more if reached the bottom of the window

	// ! showBottomPartOfSite:
	const { showBottomPartOfSiteSet } = useContext(Context)
	// ("you may like", "recently viewed", "footer")
	useEffect(() => {
		serverPostsNum < 12 ? showBottomPartOfSiteSet(true) : showBottomPartOfSiteSet(false)
	}, [serverPostsNum])
	// ? showBottomPartOfSite

	// server skip/limit is 12, so show loadMore btn if there are posts to load
	return (
		<>
			{serverPostsNum === 12 && (status === "liked" || status === "filtered" || status === "hidden") &&
				<Button ref={loadMoreBtnRef} onClick={() => skipSet(prev => prev + 12)} className="mt op0i" variant="contained">Load More</Button>
			}
			{loading && <SpinnerWithoutChildren className="mt-15" />}
		</>
	)
}