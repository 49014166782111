import parseFormAddArticle from "./parseFormAddArticle"
import parseFormAddProduct from "./parseFormAddProduct"
import parseForm from "./parseForm"

export default function defineForm(e, type) {
    // define form to use: add/edit prod/article/review

    let form = {}
    if (type === "product") {
        form = parseFormAddProduct(e)
    } else if (type === "article") {
        form = parseFormAddArticle(e)
    } else if (type === "review") {
        form = parseForm(e)
    }
    return form
}