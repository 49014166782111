import React from 'react'
import { Link } from 'react-router-dom'
import AdminIcon from '../profile/AdminIcon'

export default function OrdersIcon({ link, text, icon, iconClassName, numberLeft, numberRight }) {
    return (
        <Link to={link} className="por">
            <AdminIcon text={text}>
                {icon}
                <div className={iconClassName}>{numberLeft}/{numberRight}</div>
            </AdminIcon>
        </Link>
    )
}
