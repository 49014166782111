import SliderImage from "react-zoom-slider"
import "./index.scss"
import { useEffect, useState } from "react"

export default function CarouselProduct({ arr }) {

	// ! hide/show zoomed img onClick
	const [clicked, clickedSet] = useState(false)
	useEffect(() => {
		document.querySelector(".react-slider__lens").addEventListener("click", (e) => {
			clickedSet(prev => !prev)
		})
	}, [])

	useEffect(() => {
		if (clicked) {
			document.querySelector(".react-slider__imgZoom").style.display = "none"
		} else {
			document.querySelector(".react-slider__imgZoom").style.display = "block"
		}
	}, [clicked])
	// ? hide/show zoomed img onClick

	// images or placeholder image
	const imgs = arr?.length > 0 ? arr?.map(img => ({ image: img })) : ["https://pbs.twimg.com/profile_images/1701878932176351232/AlNU3WTK_400x400.jpg"]

	// ! hide/show prev/next btn if 0/>0 imgs
	useEffect(() => {
		if (arr?.length === 1) {
			document.querySelector(".react-slider__btnPrev").classList.add("dn")
			document.querySelector(".react-slider__btnNext").classList.add("dn")
			document.querySelector(".react-slider__ul").classList.add("dn")
		} else {
			document.querySelector(".react-slider__btnPrev").classList.remove("dn")
			document.querySelector(".react-slider__btnNext").classList.remove("dn")
			document.querySelector(".react-slider__ul").classList.remove("dn")
		}
	}, [arr])
	// ? hide/show prev/next btn if 0/>0 imgs

	return (
		<div className="w100" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
			<SliderImage
				data={imgs}
				width="100%"
				// showDescription={true}
				direction="right"
			/>
		</div>
	);
}
