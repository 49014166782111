export default function useAnimation() {

	function deleteAnimation(e) {
		const card = e.target.closest(".animation")
		if (e.target) {
			card.classList.remove("cardAnim")
			card.classList.add("delAnim")
			setTimeout(() => {
				card.style.display = "none"
			}, 500);
		}
	}

	return (
		{ deleteAnimation }
	)
}
