import React from "react"
import Products from "../product/Products";

export default function MainPage() {
	return (
		<>
			<Products />
		</>
	)
}
