import React, { useContext } from "react"
import './index.scss'
import usePreview from "./usePreview"
import { Context } from "../../Context"
import InputRequiredNativeTooltip from "../form/InputRequiredNativeTooltip"
import { Close } from "@mui/icons-material"
import { Download } from "@mui/icons-material"
import { AddPhotoAlternateOutlined } from '@mui/icons-material';
import { BRAND_COLOR } from "../../consts"
import { ArrowBack, ArrowForward } from '@mui/icons-material';

export default function AddImg({ editValue, obj }) { // obj=fullPost

	const { preview, previewMake, previewSet } = usePreview(editValue)
	const { uploadedImg, uploadedImgSet } = useContext(Context)

	async function onChange(e) {
		// 1. make previews
		// 2.	save imgs to Context
		previewMake(e.target.files)
	}

	// ! onDrop
	const onDrop = (e) => {
		e.preventDefault();
		const files = Array.from(e.dataTransfer.files);
		// Filter and keep only image files
		const imageFiles = files.filter((file) => file.type.startsWith('image/'));
		previewMake(imageFiles)
	}

	// ! deleteImg
	function deleteImg(e, ind) {
		e.preventDefault()
		// update global context
		const updatedUploadedImg = [...uploadedImg];
		updatedUploadedImg.splice(ind, 1);
		uploadedImgSet(updatedUploadedImg);

		// update local state
		const updatedPreview = [...preview];
		updatedPreview.splice(ind, 1);
		previewSet(updatedPreview);
	}

	// ! downloadImg
	function downloadImg(e, ind) {
		e.stopPropagation()
		document.querySelectorAll(".uploadedImg img")[ind].click()
	}

	// ! changeImgOrder
	function changeImgOrder(e, act, ind) {
		e.stopPropagation()
		e.preventDefault()
		const tempUploadedImgs = [...uploadedImg]
		const tempPreviews = [...preview]
		if (act === "+") {
			tempUploadedImgs.splice(ind, 1)
			tempUploadedImgs.splice(ind + 1, 0, uploadedImg[ind])
			tempPreviews.splice(ind, 1)
			tempPreviews.splice(ind + 1, 0, preview[ind])
		}
		if (act === "-") {
			tempUploadedImgs.splice(ind, 1)
			tempUploadedImgs.splice(ind - 1, 0, uploadedImg[ind])
			tempPreviews.splice(ind, 1)
			tempPreviews.splice(ind - 1, 0, preview[ind])
		}
		uploadedImgSet(tempUploadedImgs)
		previewSet(tempPreviews)
	}

	return (
		<label
			className="por"
			onDrop={onDrop}
			onDragOver={(e) => e.preventDefault()}
		>
			<input
				// required if uploadedImg.length is 0
				className="hiddenInput"
				name="img"
				type="file"
				onChange={onChange}
				multiple
				accept="image/*"
			/>
			<InputRequiredNativeTooltip required={!uploadedImg.length} isImgUploaded={uploadedImg.length} />

			{/* previews */}
			<div className="f bd w100 mih60 brL my pb">
				{preview?.length > 0 &&
					preview.map((img, ind) => (
						<div className="uploadedImg cardAnim" key={ind}>
							<img src={img} className="hei80 wid140" onClick={() => window.open(img, '_blank')} />
							<span className="poa t12 r4" onClick={(e) => deleteImg(e, ind)}>
								<Close className="poa r0 t-10" style={{ stroke: BRAND_COLOR, strokeWidth: 1, fill: BRAND_COLOR }} />
								<Download className="poa r105 t-10" style={{ stroke: BRAND_COLOR, strokeWidth: 1, fill: BRAND_COLOR }} onClick={(e) => downloadImg(e, ind)} />
								<ArrowBack className="fz25 poa t40 r105" onClick={(e) => changeImgOrder(e, "-", ind)} />
								<ArrowForward className="fz25 poa t40 r0" onClick={(e) => changeImgOrder(e, "+", ind)} />
							</span>
						</div>
					))}
				<AddPhotoAlternateOutlined className="uploadedImg p2 bn cardAnim" style={{ stroke: "#ffffff", strokeWidth: 1 }} />
			</div>
		</label>
	)
}