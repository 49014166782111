import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CART_ROUTE } from '../../consts'

export default function PaymentCancel() {

  const navigate = useNavigate()
  navigate(CART_ROUTE)

  return (
    ""
  )
}
