import { Button } from "@mui/material"

export default function OrderCarrierTrackLink({ carrier, track }) {

    const carrierLinkWithTrack = carrier?.toLowerCase().includes("ups") ? `https://www.ups.com/track?loc=en_US&requester=QUIC&tracknum=${track}` : `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=3&text28777=&tLabels=${track}`

    return (
        carrier && track &&
        <Button
            href={carrierLinkWithTrack}
            className="skipHover wfc"
            variant="contained"
        >
            TRACK SHIPMENT
        </Button>
    )
}
