import React, { useEffect, useState } from "react"
import useCartProducts from "./useCartProducts"
import ButtonLink from "../form/ButtonLink"
import Spinner from "../other/Spinner"
import { MAIN_ROUTE } from "../../consts"
import CartShipmentCost from "./CartShipmentCost"
import useSubtotal from "./useSubtotal"
import OrderInfo from "../order/OrderInfo"

// for both user cart prods & order prods
export default function CartProducts() {

	const { userOrOrder, cartProducts, allProductsTotalPrice, varLink: link, varLink2: link2, varText: text, varBtnText: btnText, varBtnText2: btnText2, varBtnFn, varTitle, varTitle2, varBtnText3: btnText3, varBtnFn3: btnFn3 } = useCartProducts()
	const loading = cartProducts.length === 0
	const { subtotal } = useSubtotal({ userOrOrder, allProductsTotalPrice })

	// used for: "Your cart is empty"
	const [isTimePassed, isTimePassedSet] = useState(false)
	useEffect(() => {
		setTimeout(() => isTimePassedSet(true), 2000);
	}, [])

	return (
		cartProducts.length > 0
			?
			<div className="fcc g4 wL m0a">
				<div className="fc w100 wM asfs">
					{varTitle2 && <section className="title2 tac m mt0">{varTitle2}</section>}
					<div className="wM w100">
						<Spinner loading={loading}>
							<CartShipmentCost userOrOrder={userOrOrder} />
							{cartProducts}
						</Spinner>
					</div>
				</div>
				<section className="tac mb mt wS w100 asfs subTotal">
					<OrderInfo text={varTitle} />
					<div>Subtotal: ${subtotal}</div>
					<div className="my">{text}</div>
					{btnText3 && <ButtonLink className="dangerBorder danger w100 mb" variant="outlined" onClick={btnFn3}>{btnText3}</ButtonLink>}
					<ButtonLink variant="outlined" href={MAIN_ROUTE} className="mb">CONTINUE SHOPPING</ButtonLink>
					{btnText2 && <ButtonLink variant="outlined" className="mb" href={link2}>{btnText2}</ButtonLink>}
					{btnText && <ButtonLink variant="contained" href={link} onClick={varBtnFn}>{btnText}</ButtonLink>}
				</section>
			</div>
			:
			<>
				{(cartProducts.length === 0 && isTimePassed) && <div className="opAnim title tac brandi mt4">Your cart is empty</div>}
			</>
	)
}
