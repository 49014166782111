import { Close } from "@mui/icons-material";
import * as api from "../../api"
import { useLocation } from "react-router-dom";

export default function DeleteFile({ editValue, productId, uploadedNameSet, uploadedFilesSet, uploadedName }) {

    const location = useLocation().pathname

    async function deleteFile() {
        const res = await api.deleteImg(editValue) // del file from server
        uploadedNameSet("") // del fileName from context
        uploadedFilesSet([]) // del file from context
        api.pullPush({ col: "product", colId: productId, field: "archive", action: "clear" }) // null DB product record "archive"
    }

    return (
        uploadedName &&
        <Close className="asc por r10" onClick={deleteFile} />
    )
}
