import { TextField } from "@mui/material"
import React from "react"
import useEditInput from "./useEditInput"
import { DANGER_COLOR } from "../../consts"

export default function Input(props) {

	const { editValue, isDisabled, variant = "standard", size = "normal", outerValueSet, required } = props
	const { value, valueSet } = useEditInput(editValue)

	// prevent type number scroll (and +/- while scrolling)
	function typeNumberPreventScroll(e) {
		e.target.blur()
		e.stopPropagation()
		setTimeout(() => { e.target.focus() }, 0)
	}

	function onChange(e) {
		valueSet(e.target.value) // set inner input value
		outerValueSet && outerValueSet(e.target.value) // set outer (parent) value
		props.onChange && props.onChange(e.target.value) // run props.onChange if passed
	}

	return (
		<TextField
			className="mb opAnim"
			disabled={isDisabled}
			{...props}
			value={value}
			onChange={onChange}
			variant={variant}
			size={size}
			onWheel={typeNumberPreventScroll}
			style={{ borderBottom: !value && required ? `1px solid ${DANGER_COLOR}` : "" }}
		/>
	)
}
