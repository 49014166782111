import { useContext } from "react"
import Spinner from "../other/Spinner"
import { Button } from "@mui/material"
import { Context } from "../../Context"
import { useLocation } from "react-router-dom"
import { CART_SHIPPING } from "../../consts"

export default function StripeBtn({ isBtnDisabled, varText, isStripeLoading = false }) {

	let showBtn = true

	// if in CART_SHIPPING check if calculateShippingBtn was Clicked => then show StripeBtn
	const location = useLocation().pathname
	const { calculateShippingBtnClicked } = useContext(Context)
	location === CART_SHIPPING && (showBtn = !!calculateShippingBtnClicked)
	location.includes("admin") && (isBtnDisabled = false) // button is always NOT disabled for admin in admin locations

	return (
		showBtn && varText &&
		<Spinner loading={isStripeLoading}>
			<Button
				type="submit"
				disabled={isBtnDisabled}
				variant="contained"
				className="mt"
			>
				{varText}
			</Button>
		</Spinner>
	)
}
