import React, { useContext, useState } from 'react'
import AddImg from '../addImg/AddImg'
import Input from '../form/Input'
import { Button } from '@mui/material'
import InputsAddable from '../form/InputsAddable'
import InputsCustom from '../form/InputsCustom'
import AddFile from '../addFile/AddFile'
import getServerFileName from '../../utils/getServerFileName'
import { Context } from '../../Context'
import Checkbox from '../form/Checkbox'
import TextEditor from '../textEditor/TextEditor'

// addProduct/editProduct
export default function Product_add({ obj, type }) {

	// * gray cause of eval
	const { addPost, editPost, fullPost, varText, varFn } = obj

	const { uploadedArchiveSet } = useContext(Context)
	function setUploadedFileToContext(file) {
		// * only saves uploadedArchive to Context, archive upload happens in useAddArhive
		uploadedArchiveSet(file) // save archive to Context
	}

	const [isDigitalSaleOnlyChecked, isDigitalSaleOnlyCheckedSet] = useState(false)

	const type_ = fullPost?.type || type

	return (
		<>
			<div className="title tac">{varText}</div>
			{/*                                   eg: addPost(e, "product")} */}
			<form className="fc" onSubmit={(e) => eval(`${varFn}(e, "product", fullPost?.img)`)}>
				<AddImg editValue={fullPost?.img} obj={fullPost} />
				<Input required editValue={fullPost?.title} name="title" label="title" />
				<Input required editValue={fullPost?.brand || "sendt"} name="brand" label="brand" />
				<Input required editValue={fullPost?.tags} name="tags" label="tags" className="muiHelperTextAbsolute mb" helperText="separated by comma" />
				<Input required editValue={fullPost?.price} name="price" type="number" label="price" />
				<TextEditor editValue={fullPost?.desc} label="description" className="mb" type={type_} />

				<div className="title2 tac mb05">Digital download</div>
				<Checkbox name="isDigitalSaleOnly" editValue={fullPost?.isDigitalSaleOnly} label="The Product is Digital Sale Only" labelClassName="fcc g05 mb" className="db mb wid20 hei20 por t8" isCheckedSet={isDigitalSaleOnlyCheckedSet} />
				<AddFile name="archive" required={isDigitalSaleOnlyChecked || fullPost?.isDigitalSaleOnly} text='add "digital download"' className="bd brL mb" accept="*/*" editValue={getServerFileName(fullPost?.archive?.[0])} onChange={(e) => setUploadedFileToContext(e)} productId={fullPost?._id} uploadedFilesSet={uploadedArchiveSet} />

				{!isDigitalSaleOnlyChecked &&
					<>
						<div className="title2 tac blurAnim">Measurements</div>
						<Input required editValue={fullPost?.weight} name="weight" label="weight (g)" type="number" />
						<Input required editValue={fullPost?.length} name="length" label="length (cm)" type="number" />
						<Input required editValue={fullPost?.width} name="width" label="width (cm)" type="number" />
						<Input required editValue={fullPost?.height} name="height" label="height (cm)" type="number" />
						<Input required editValue={fullPost?.buffer} name="buffer" label="buffer (cm)" type="number" />
					</>
				}

				<InputsCustom obj={fullPost} />
				<InputsAddable type="characteristic" title="Characteristics" editValue={fullPost?.characteristics} />
				<InputsAddable type="information" title="Information" editValue={fullPost?.informations} />
				<Button type="submit" variant="contained">{varText}</Button>
			</form>
		</>
	)
}
