import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import SelectOptions from './SelectOptions';

export default function SelectNativeMui(props) {

	const { name, id, defaultValue, arr, placeholder, required, editValue, type = "strArr", isDisabled, opacity, children, width = "50%" } = props

	React.useEffect(() => {
		editValue && valueSet(editValue || "")
	}, [editValue])

	const [value, valueSet] = React.useState(editValue || "")

	function onChange(e) {
		valueSet(e.target.value)
		props.onChange && props.onChange(e.target.value)
	}

	return (
		<Box sx={{ width, opacity }}>
			<FormControl fullWidth>
				<InputLabel variant="standard" htmlFor="uncontrolled-native">
					{placeholder} *
				</InputLabel>
				<NativeSelect
					defaultValue={defaultValue}
					inputProps={{ name, id }}
					value={value}
					disabled={isDisabled}
					{...props}
					onChange={onChange} // !! must be lower than ...props
				>
					{/* use SelectOptions || children to render options*/}
					<SelectOptions type={type} arr={arr} />
					{children}
				</NativeSelect>
			</FormControl>
		</Box>
	);
}