import React from "react"
import Input from "../form/Input"
import { Button } from "@mui/material"
import useOrderSendEmailTrack from "./useOrderSendEmailTrack"
import CartSteps from "../cart/CartSteps"
import usePostFull from "../../hooks/usePostFull"
import OrderCarrierTrackLink from "./OrderCarrierTrackLink"

export default function OrderSendEmailTrack() {
	// admin clicks to one order
	// sees products in order
	// clicks CONTINUE TO SHIPPING
	// sees user's shipping info(where to deliver) => makes `DELIVERY`
	// clicks SEND EMAIL
	// (`DELIVERY` made): admin has `track number`
	// admin copies `track number` to input and SENDS EMAIL with `track number` to user

	const { sendEmail, varBtnText, showBtn, varBtnHelpText } = useOrderSendEmailTrack()

	// get order from orderId
	const orderId = localStorage.getItem("orderId")
	const { fullPost: order } = usePostFull("order", orderId)

	const { email, _id: _orderId, track, carrier, notesToCustomer, shipDate, service } = order // user order email & id

	return (
		<>
			<CartSteps step={3} />
			<section className="wM m0a">
				<form onSubmit={sendEmail}>
					{_orderId && <Input editValue={_orderId} disabled name="_id" label="order id" className="mb" />}
					{email && <Input editValue={email} disabled name="email" label="user email" className="mb" />}
					{track && <Input editValue={track} required name="track" label="tracking number" />}
					{carrier && <Input editValue={carrier} required name="carrier" label="carrier" />}
					{service && <Input editValue={service} required name="service" label="service" />}
					{shipDate && <Input editValue={shipDate} required name="shipDate" label="ship date" />}
					{notesToCustomer && <Input editValue={notesToCustomer} required name="notesToCustomer" label="notes to customer" multiline />}
					<OrderCarrierTrackLink carrier={carrier} track={track} />
					{showBtn && varBtnText && <Button type="submit" className="mt" variant="outlined">{varBtnText}</Button>}
					{varBtnHelpText && <div className="mt tac fz14">{varBtnHelpText}</div>}
				</form>
			</section>
		</>
	)
}
