/* 
no uploaded img => show tooltip
used with custom type="file" where native type="file" is hidden
placed inside parent with className="por" (position: relative)
*/

export default function InputRequiredNativeTooltip({ isImgUploaded, required }) {
	return (
		!isImgUploaded && <input required={required} className="hiddenInput" />
	)
}
