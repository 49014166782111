import React from "react"
import "./index.scss"
import DigitalDownload from "../digitalDownload/DigitalDownload";
import HasDigitalDownload from "../digitalDownload/HasDigitalDownload";
import CartCardImg from "./CartCardImg";
import CartCardPrice from "./CartCardPrice";
import CartCardCustomFields from "./CartCardCustomFields";
import CartCardDelete from "./CartCardDelete";
import CartCardWeight from "./CartCardWeight";
import TitleWithHint from "../other/TitleWithHint";

export default function CartCard({ obj, totalPrice, className }) {

	const { img, title, price, _id: productId, quantity, archive, status, isDigitalSaleOnly } = obj

	return (
		<section className={`por f aic m0a mb cartCard animation cardAnim ${className ? className : ""} ${status === "hidden" ? "dangerBg" : ""} ${status === "deleted" ? "dangerBg2" : ""}`}>

			{status === "hidden" && <div className="w100 mb">* this product was marked as suspended</div>}
			{status === "deleted" && <div className="w100 mb">* this product was marked as deleted</div>}

			<div className="imgContentWrap">
				<CartCardImg obj={obj} />
				{/* section 1 */}
				<div className="fc oh contentWrap">
					<TitleWithHint className="title2 wsn toe cartCard__title oh wsn toe" title={title} />
					<CartCardCustomFields obj={obj} />
					<div><span className="fw500">Quantity:</span> {quantity}</div>
					<CartCardWeight obj={obj} />
				</div>
			</div>

			<CartCardDelete obj={obj} className="cartCardDelete" />
			{/* section 2 */}
			<div className="fc poa r10 b10">
				<CartCardPrice obj={obj} totalPrice={totalPrice} className="asfe fw600" />
				<div className="brand">${price}+ each</div>
			</div>

			<div className="w100">
				<DigitalDownload download={archive} />
				<HasDigitalDownload download={archive} isDigitalSaleOnly={isDigitalSaleOnly} className="hasDigitalDownload" />
			</div>
		</section>
	)
}
