import { useContext } from "react"
import { Context } from "../../Context"
import { baseURL } from "../../consts"

export default function usePastedOrDroppedFile(valueSet, type) {

	const { pastedOrDroppedImgSet } = useContext(Context)

	// ! processPastedOrDroppedFile
	function processPastedOrDroppedFile(pastedOrDroppedFile) {
		const pastedOrDroppedFileName = pastedOrDroppedFile.name.split(".")[0] + Date.now() + "." + pastedOrDroppedFile.name.split(".")[1] // eg: image1695902498918.png
		const fixedPastedOrDroppedFileName = pastedOrDroppedFileName.replace(/\s/g, "") // replace spaces
		const fileRenamed = new File([pastedOrDroppedFile], fixedPastedOrDroppedFileName)
		pastedOrDroppedImgSet(prev => [...prev, fileRenamed])
		valueSet(prev => prev + `\n![image](${baseURL}/upload/${type}Images/${fileRenamed.name})`)
	}

	// ! savePastedImgOnServer
	function savePastedImgOnServer(e) {
		if (e.clipboardData.files.length > 0) {
			processPastedOrDroppedFile(e.clipboardData.files[0])
		}
	}

	// ! saveDroppedImgOnServer
	function saveDroppedImgOnServer(e) {
		e.preventDefault()
		if (e.dataTransfer.files.length > 0) {
			processPastedOrDroppedFile(e.dataTransfer.files[0])
		}
	}

	return (
		{ savePastedImgOnServer, saveDroppedImgOnServer }
	)
}
