import React from "react"
import Category from "./Category"
import catList from "./catList"
import { CarouselBanner } from "../carousel/CarouselBanner"

export default function Categories() {

	const colsNum = catList.length < 3 ? catList.length : 3 // Categories slider display 1/2 imgs if catList has < 3 cats
	const hideArrow = catList.length < 4 ? true : false // show arrows if > 3 cats

	return (
		<div className="fcc g mb">
			<CarouselBanner cols={colsNum} hideArrow={hideArrow}>
				{catList?.map(({ src, title }) => <Category key={src} src={src} title={title} />)}
			</CarouselBanner>
		</div>
	)
}
