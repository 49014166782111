import React from "react"
import { Link } from "react-router-dom"

export default function CardLink(props) {

	const { children, obj, className } = props
	const { type, _id } = obj // type=product/article/...

	function onClick() {
		window.scrollTo(0, 0)
		props.onClick && props.onClick()
	}

	return (
		<Link to={`/${type}/${_id}`} className={`tdn ${className}`} onClick={onClick}>
			{children}
		</Link>
	)
}
