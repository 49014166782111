import { useEffect, useState } from "react"
import * as api from "../api"
import { useLocation, useNavigate } from "react-router-dom"
import { MAIN_ROUTE } from "../consts"

export default function usePostFull(type, id) {	// type=product/article/comment/review...

	const [fullPost, fullPostSet] = useState({})
	const [loading, loadingSet] = useState(true)
	const navigate = useNavigate()
	const location = useLocation().pathname

	useEffect(() => {
		async function getFullPost() {
			const res = await api.fullPost(type, id)
			res && fullPostSet(res)
			res && loadingSet(false)
			const notNavigate = ["/add/", "about", "terms", "privacy", "return", "shipping"]
			const isNotNavigate = notNavigate.find(item => location.match(item))
			!res && !isNotNavigate && navigate(MAIN_ROUTE) // if no prodId => redirect; ADD_PRODUCT/ARTICLE has no prodId
		}

		getFullPost()
	}, [type, id])

	return (
		{ fullPost, loading }
	)
}
