import Product_article_card from "./product_article_card"
import Article_card_bottom from "./Article_card_bottom";

export default function Article_card_design2({ obj }) {
    return (
        <Product_article_card
            obj={obj}
            childrenBottom={
                <div className="f jcsb poa b2 l8 w95">
                    <Article_card_bottom obj={obj} />
                </div>
            }
        >


        </Product_article_card>
    )
}
