import React from "react"
import { Edit } from "@mui/icons-material";
import LikeIcon from "../icons/LikeIcon";
import ForUserRole from "../other/ForUserRole";
import Share from "../share/Share";
import Product_article_card_top_hide from "./Product_article_card_top_hide";
import Product_article_card_top_delete from "./Product_article_card_top_delete";

// TODO rename to PostIcons
export default function Product_article_card_top({ obj, children, className }) { // type=product/article

	const { type, _id } = obj

	return (
		<>
			<div className={`zi2 f jcsb aic card__top ${className ? className : ""}`} onClick={(e) => e.stopPropagation()}>

				{/* if there are children place them on the left (margin-right:auto) */}
				{children &&
					<div className="mra">
						{children}
					</div>
				}

				<LikeIcon obj={obj} />
				{/* eg: /edit/product|article|smth/_id */}
				<ForUserRole role="admin">
					<Edit style={{ height: 30, width: 30 }} onClick={() => window.location.href = `/edit/${type}/${_id}`} />
					<Product_article_card_top_hide obj={obj} />
					<Product_article_card_top_delete obj={obj} />
				</ForUserRole>
				<Share obj={obj} />
			</div>
		</>
	)
}
