import React, { useContext } from 'react'
import Or from '../auth/Or'
import { PendingOutlined, CheckCircleOutlined, CloudDownloadOutlined, CancelOutlined } from '@mui/icons-material'
import { ADMIN_ORDERS_CANCELED, ADMIN_ORDERS_DIGITAL, ADMIN_ORDERS_NEW, ADMIN_ORDERS_SENT, USER_ORDERS_CANCELED, USER_ORDERS_DIGITAL, USER_ORDERS_NEW, USER_ORDERS_SENT } from '../../consts'
import useNotCheckedOrders from './useNotCheckedOrders'
import { Context } from '../../Context'
import OrdersIcon from './OrdersIcon'

export default function Orders({ role }) {

	const { user } = useContext(Context)
	const { notCheckedPending, notCheckedSent, notCheckedDigital, notCheckedCancelled, allOrdersPendingNum, allOrdersSentNum, allOrdersDigitalNum, allOrdersCancelledNum } = useNotCheckedOrders(role, user._id)

	// define links
	let linkToNew, linkToSent, linkToDigital, linkToCancelled
	if (role === "user") { // for user orders
		linkToNew = USER_ORDERS_NEW
		linkToSent = USER_ORDERS_SENT
		linkToDigital = USER_ORDERS_DIGITAL
		linkToCancelled = USER_ORDERS_CANCELED
	}
	if (role === "admin") { // for admin orders
		linkToNew = ADMIN_ORDERS_NEW
		linkToSent = ADMIN_ORDERS_SENT
		linkToDigital = ADMIN_ORDERS_DIGITAL
		linkToCancelled = ADMIN_ORDERS_CANCELED
	}

	return (
		<section className="fcc g w100 px0">
			<Or text={`${role.toUpperCase()} ORDERS`} />
			<OrdersIcon link={linkToNew} text="pending" icon={<PendingOutlined />} iconClassName="iconCount iconCount_wide t22" numberLeft={notCheckedPending} numberRight={allOrdersPendingNum} />
			<OrdersIcon link={linkToSent} text="sent" icon={<CheckCircleOutlined />} iconClassName="iconCount iconCount_wide t22" numberLeft={notCheckedSent} numberRight={allOrdersSentNum} />
			<OrdersIcon link={linkToDigital} text="digital" icon={<CloudDownloadOutlined />} iconClassName="iconCount iconCount_wide t21" numberLeft={notCheckedDigital} numberRight={allOrdersDigitalNum} />
			<OrdersIcon link={linkToCancelled} text="cancelled" icon={<CancelOutlined />} iconClassName="iconCount iconCount_wide t22" numberLeft={notCheckedCancelled} numberRight={allOrdersCancelledNum} />
		</section>
	)
}
