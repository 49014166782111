import { useEffect, useState } from "react"

export default function useEditInput(editValue) {

    const [value, valueSet] = useState("") // input value

	// for edit: pass old value to editValue prop
	// eg: <Input editValue={obj.inputName} />
	useEffect(() => {
		editValue && valueSet(editValue)
	}, [editValue])


  return { value, valueSet }
}
