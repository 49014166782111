import React from "react"
import "./index.scss"
import Product_card_design1 from "./Product_card_design1"
import Product_card_design2 from "./Product_card_design2"

export default function product_card({ obj, cardsDesign, design1Classes }) {
	return (
		cardsDesign === "cardsDesign1"
			?
			<Product_card_design1 obj={obj} design1Classes={design1Classes} />
			:
			<Product_card_design2 obj={obj} />
	)
}
