import { useContext } from "react"
import useOrders from "../../hooks/useOrders"
import { Context } from "../../Context"

export default function useIsOrderedByUser(productId) {

	const { user } = useContext(Context)
	let { orders } = useOrders()

	let isOrderedByUser = false

	orders?.map(order => {
		if (order?.userId === user?._id) {
			order.cart.map(prod => {
				if (prod._id === productId) {
					isOrderedByUser = true
				}
			})
		}
	})

	return (
		{ isOrderedByUser }
	)
}
