import React, { useState, useEffect } from 'react';
import "./index.scss"

export default function LoadingBar({ imgState, isImgsEnded }) {
    const [loadingProgress, setLoadingProgress] = useState(0);

    useEffect(() => {
        const totalDuration = 1000;
        const increment = (100 / totalDuration) * 70; // Adjust based on the desired loading time

        const interval = setInterval(() => {
            if (loadingProgress < 100) {
                setLoadingProgress(loadingProgress + increment);
            } else {
                clearInterval(interval);
            }
        }, 50); // Interval duration remains constant

        // null loadingProgress
        if (loadingProgress === 100) {
            setLoadingProgress(0);
        }

        return () => clearInterval(interval);
    }, [loadingProgress]);

    // image switched null loadingProgress
    useEffect(() => {
        setLoadingProgress(0)
    }, [imgState])

    return (
        !isImgsEnded &&
        <div className="loading-bar">
            <div className="filler" style={{ width: `${loadingProgress}%` }}></div>
        </div>
    );
};