import React, { useEffect } from 'react'
import useEditInput from './useEditInput'

export default function Checkbox(props) {

    const { className, label, labelClassName, name, editValue, isCheckedSet } = props
    const { value, valueSet } = useEditInput(editValue)

    useEffect(() => {
        isCheckedSet && isCheckedSet(value)
    }, [value])

    return (
        <label className={labelClassName}>
            <input
                type="checkbox"
                name={name}
                className={className}
                checked={value}
                onChange={(e) => valueSet(e.target.checked)}
            />
            <div>{label}</div>
        </label>
    )
}
