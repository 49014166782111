import IsInCartIcon from "../product/IsInCartIcon"
import RatingCount from "../product/RatingCount"
import Product_card_bottom from "./Product_card_bottom"
import Product_article_card from "./product_article_card"

export default function Product_card_design2({ obj }) {

    const { brand, _id } = obj

    return (
        <Product_article_card
            obj={obj}
            childrenBottom={
                <div className="fcc jcsb poa b5 l8 w95">
                    <Product_card_bottom obj={obj} />
                </div>
            }
        >

            {/* product & article cards are 70% same; product_card diff here */}
            <div className="gray tac card__brand">{brand}</div>
            <RatingCount _id={_id} />

        </Product_article_card>
    )
}