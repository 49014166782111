import React from 'react';
import TextField from '@mui/material/TextField';
import useEditInput from './useEditInput';
import { DANGER_COLOR } from '../../consts';

export default function InputWithValidation(props) {
  const inputRef = React.createRef();

  const handleValidation = () => {
    const inputElement = inputRef.current;
    if (inputElement.checkValidity()) {
      // Input is valid; perform further actions.
    } else {
      // Input is invalid; show the browser error message (tooltip).
      inputElement.reportValidity();
    }
  };

  function onChange(e) {
    handleValidation()
    valueSet(e.target.value) // set inner input value
    outerValueSet && outerValueSet(e.target.value) // set outer (parent) value
  }

  const { pattern, title, editValue, outerValueSet, isDisabled } = props
  const { value, valueSet } = useEditInput(editValue)

  return (
    <div>
      <TextField
        {...props}
        variant="standard"
        type="text" // text for all types
        inputProps={{
          pattern,
          title, // tooltip
          required: true,
        }}
        inputRef={inputRef}
        onChange={onChange}
        value={value}
        disabled={isDisabled}
        style={{ borderBottom: !value ? `1px solid ${DANGER_COLOR}` : "" }}
      />
    </div>
  );
}