import { Share } from "@mui/icons-material";
import { useContext } from "react";
import { Context } from "../../Context";
import Helmet from "../helmet/Helmet";
import parseTextEditorImg from "../textEditor/parseTextEditorImg"
import { useLocation } from "react-router-dom";
import ForUserRole from "../other/ForUserRole";

export default function _Share({ obj }) {

	const { dialogSet } = useContext(Context)
	let { type, title, img, textEditorValue, _id } = obj
	img = img?.[0] || parseTextEditorImg(textEditorValue)
	const location = useLocation().pathname

	function onClick() {
		dialogSet(prev => ({
			...prev,
			dialogShow: true,
			dialogContentName: "share",
			dialogContent: { type, _id },
			dialogTitle: `Share ${type}: ${title}`,
			dialogImg: img,
			dialogHasButtons: false
		}))
	}

	return (
		<ForUserRole role="user">
			{!location.includes("/hidden/") &&
				<>
					<Helmet img={img} />
					<Share className="" onClick={onClick} />
				</>
			}
		</ForUserRole>
	)
}
