import React from "react"
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import useLikeIcon from "./useLikeIcon";
import { BRAND_COLOR, LIKED_PRODS_ROUTE } from "../../consts";
import useNoUser from "../../hooks/useNoUser";
import useSnackbar from "../../hooks/useSnackbar";
import ForUserRole from "../other/ForUserRole";

// type=product/article/... 
// _id=productId/articleId/...
export default function LikeIcon({ obj }) { // obj=product/article/...

	const { type } = obj
	const { likeDislike, isProductLiked, articleLikesCount } = useLikeIcon(obj)
	const { noUserRedirect } = useNoUser()
	const { showSnackbar } = useSnackbar()

	function onClick() {
		noUserRedirect()
		likeDislike()
		if (type === "product") {
			const action = isProductLiked ? "removed from " : "added to "
			showSnackbar({ text: action, linkText: "liked", link: LIKED_PRODS_ROUTE })
		}
	}

	return (
		<ForUserRole role="user">
			{
				// user likes includes productId || article.likes.length > 0 => show like/liked icon
				isProductLiked || articleLikesCount > 0
					?
					<div className="fcc por">
						<Favorite className={type === "article" ? "mr" : ""} onClick={onClick} style={{ fill: BRAND_COLOR }} />
						<span className="poa l35 fw600">{type === "article" && articleLikesCount}</span>
					</div>
					:
					<div className="fcc por">
						<FavoriteBorder className={type === "article" ? "mr" : ""} onClick={onClick} />
						<span className="poa l35 fw600">{type === "article" && articleLikesCount}</span>
					</div>}
		</ForUserRole>
	)
}
