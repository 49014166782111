import Product_article_card from "./product_article_card"
import Product_article_card_design1_bigText from "./Product_article_card_design1_bigText";
import Article_card_bottom from "./Article_card_bottom";

export default function Article_card_design1({ obj, design1Classes }) {

    const { textEditorValue } = obj

    return (
        <div className="por m0a">
            <Product_article_card
                obj={obj}
                {...design1Classes}
            >

                {/* product & article cards are 70% same; article_card diff here */}
                <div>
                    <div className="f jcsb aife wid545">
                        <Product_article_card_design1_bigText text={textEditorValue} />
                    </div>
                </div>


            </Product_article_card>

            <div className="f jcsb aife wid545 poa b5 r15">
                <Article_card_bottom obj={obj} />
            </div>
        </div>
    )
}
