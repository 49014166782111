import { useState, useEffect } from "react";

export default function useZipCodeValidator({ zipCodeValue, cityValue, stateValue, cityValueSet }) {

	const [isValidZipCode, isValidZipCodeSet] = useState(false)
	const [isLoading, isLoadingSet] = useState(true)
	const [okCity, okCitySet] = useState("")

	useEffect(() => {
		const validateZipCode = () => {
			isValidZipCodeSet(false)

			if (!zipCodeValue) return
			if (!cityValue) return
			if (!stateValue) return

			const client = new XMLHttpRequest();
			client.open('GET', `https://api.zippopotam.us/us/${zipCodeValue}`, true);
			client.onreadystatechange = function () {
				if (client.readyState === 4) {
					const res = JSON.parse(client.responseText)
					if (Object.keys(res).length === 0) return

					const state = res.places[0]["state abbreviation"]
					const place_name = res.places[0]["place name"] // eg: New York City
					const post_code = res["post code"]

					// ! if google's pasted city is wrong paste city from useZipCodeValidator
					const isCityOk = place_name.toLowerCase().trim().includes(cityValue.toLowerCase().trim())
					!isCityOk && (okCitySet(place_name))
					// ? if google's pasted city is wrong paste city from useZipCodeValidator

					const isStateOk = state === stateValue
					// console.log({ isCityOk, isStateOk })
					// console.log({ zipCodeValue, cityValue, stateValue })

					if (!isCityOk) return
					if (!isStateOk) return
					// ! ok
					isValidZipCodeSet(true)
					isLoadingSet(false)
				}
			};
			client.send();
		};

		validateZipCode()
	}, [zipCodeValue, cityValue, stateValue])

	// ! if google's pasted city is wrong paste city from useZipCodeValidator
	useEffect(() => {
		okCity && cityValueSet(okCity) // correct city if not ok city came from google in ShippingAddressInput (Shipping)
		// okCity && okCitySet("") // null okCity for multiple uses
	}, [okCity])
	// ? if google's pasted city is wrong paste city from useZipCodeValidator

	return (
		{ isValidZipCode, isLoading, okCity }
	)
}
