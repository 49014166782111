import { useEffect, useState } from "react"
import * as api from "../api"

export default function useOrders() {

	const [orders, ordersSet] = useState([])
	useEffect(() => {
		async function getAllOrders() {
			const res = await api.getAllOrders()
			res && ordersSet(res)
		}

		getAllOrders()
	}, [])

	return (
		{ orders }
	)
}
