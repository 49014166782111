import { useEffect, useState } from "react"
import * as api from "../../api"
import { useLocation } from "react-router-dom"
import { CART_ROUTE } from "../../consts"

export default function useOneOrder(_id) {

	const location = useLocation().pathname

	const [order, orderSet] = useState([])

	useEffect(() => {
		async function getOneOrder() {
			if(location === CART_ROUTE) return
			const res = await api.getOneOrder(_id)
			orderSet(res)
		}

		getOneOrder()
	}, [_id])

	return { order }
}
