import parseFormTags from "./parseFormTags"
import parseTextEditorValue from "./parseTextEditorValue"

export default function parseFormAddArticle(e) { // e=event from onSubmit (html form)

    let form = {} // {"any field 1": "@","any field 2": "123","any field 3": "abc"}
    let tags = []

    // put all `inputs` values to "form"
    e.target.querySelectorAll("input, select, textarea").forEach(each => {

        const textEditorValue = parseTextEditorValue()

        if (each.name.includes("tags")) {
            tags = parseFormTags(each.value)
        }

        if (each.name && each.value) {
            form = { ...form, [each.name]: each.checked || each.value, tags, textEditorValue }
        }
    })

    return (
        form
    )
}