import { CircularProgress } from '@mui/material'
import { BRAND_COLOR } from '../../consts'

export default function SpinnerWithoutChildren(props) {

    const { className } = props

    return (
        <div className={`fcc ${className ? className : ""}`}>
            <CircularProgress style={{ color: BRAND_COLOR }} {...props} />
        </div>
    )
}
