import React, { useContext } from "react"
import Input from "../form/Input"
import useSubscribe from "./useSubscribe"
import { Button } from "@mui/material"
import { Context } from "../../Context"
import Spinner from "../other/Spinner"

export default function Subscribe() {

	const { user } = useContext(Context)
	const { subscribe, isSubscribed, loading, texts } = useSubscribe(user?.email)

	return (
		<Spinner loading={loading}>
			<section className="wM m0a">
				<div className="title tac mb">{texts[1]}</div>
				<div className="tac mb">
					{texts[2]}
				</div>
				<Input disabled name="email" type="email" placeholder="email" label="email" editValue={user?.email} helperText="registration email used" />
				<Button onClick={subscribe} variant="contained">{!isSubscribed ? "Subscribe" : "UnSubscribe"}</Button>
			</section>
		</Spinner>
	)
}