import useOrders from '../../hooks/useOrders'

export default function useNotCheckedOrders(role, userId) {

	let notCheckedPending = 0
	let notCheckedSent = 0
	let notCheckedDigital = 0
	let notCheckedCancelled = 0
	let allOrdersNum = 0
	let allOrdersPendingNum = 0
	let allOrdersSentNum = 0
	let allOrdersDigitalNum = 0
	let allOrdersCancelledNum = 0

	const { orders } = useOrders()
	if (orders || orders?.length > 0) {

		// ! notCheckedPending
		orders?.map(order => {
			if (role === "user") { // user
				return order.userId === userId && order.isChecked === false && order.status === "pending" && (notCheckedPending += 1)
			}
			if (role === "admin") { // admin
				return order.isCheckedByAdmin === false && order.status === "pending" && (notCheckedPending += 1)
			}
		})
		// ! notCheckedSent
		orders?.map(order => {
			if (role === "user") { // user
				return order.userId === userId && order.isChecked === false && order.status === "sent" && (notCheckedSent += 1)
			}
			if (role === "admin") { // admin
				return order.isCheckedByAdmin === false && order.status === "sent" && (notCheckedSent += 1)
			}
		})
		//  ! notCheckedDigital
		orders?.map(order => {
			if (role === "user") { // user
				return order.userId === userId && order.isChecked === false && order.status === "digital download" && (notCheckedDigital += 1)
			}
			if (role === "admin") { // admin
				return order.isCheckedByAdmin === false && order.status === "digital download" && (notCheckedDigital += 1)
			}
		})
		// ! notCheckedCancelled
		orders?.map(order => {
			if (role === "user") { // user
				return order.userId === userId && order.isChecked === false && order.status === "cancelled" && (notCheckedCancelled += 1)
			}
			if (role === "admin") { // admin
				return order.isCheckedByAdmin === false && order.status === "cancelled" && (notCheckedCancelled += 1)
			}
		})
		// ! allOrdersPendingNum
		orders?.map(order => {
			if (role === "user") { // user
				order.userId === userId && order.status === "pending" && (allOrdersPendingNum += 1)
			}
			if (role === "admin") { // admin
				order.status === "pending" && (allOrdersPendingNum += 1)
			}
		})
		// ! allOrdersSentNum
		orders?.map(order => {
			if (role === "user") { // user
				return order.userId === userId && order.status === "sent" && (allOrdersSentNum += 1)
			}
			if (role === "admin") { // admin
				return order.status === "sent" && (allOrdersSentNum += 1)
			}
		})
		// ! allOrdersDigitalNum
		orders?.map(order => {
			if (role === "user") { // user
				return order.userId === userId && order.status === "digital download" && (allOrdersDigitalNum += 1)
			}
			if (role === "admin") { // admin
				return order.status === "digital download" && (allOrdersDigitalNum += 1)
			}
		})
		// ! allOrdersCancelledNum
		orders?.map(order => {
			if (role === "user") { // user
				return order.userId === userId && order.status === "cancelled" && (allOrdersCancelledNum += 1);
			}
			if (role === "admin") { // admin
				return order.status === "cancelled" && (allOrdersCancelledNum += 1);
			}
		});


		allOrdersNum = orders?.length
	}

	return { notCheckedPending, notCheckedSent, notCheckedDigital, notCheckedCancelled, allOrdersNum, allOrdersPendingNum, allOrdersDigitalNum, allOrdersSentNum, allOrdersCancelledNum }
}
