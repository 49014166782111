import { useContext } from "react"
import { Context } from "../Context"

export default function useUserOrFakeUser() {

    let { user } = useContext(Context)
    !user && (user = JSON.parse(localStorage.getItem("user"))) // if no user, get user.cart from LS

    return user
}
