import React from "react"
import { CloudDownload } from "@mui/icons-material"
import { CART_ROUTE } from "../../consts"
import DigitalDownloadTitle from "./DigitalDownloadTitle"

export default function HasDigitalDownload({ download, className, isDigitalSaleOnly }) {
	return (
		(window.location.pathname.includes("/product/") || window.location.pathname === CART_ROUTE) &&
		download?.length > 0 &&
		<div
			className={`f aic fsi mt ${className ? className : ""}`}
			title="Download content after purchase"
		>
			<CloudDownload />
			<DigitalDownloadTitle isDigitalSaleOnly={isDigitalSaleOnly} />
		</div>
	)
}
