import React from 'react'
import { Link } from 'react-router-dom'

export default function CartStep({ className1, className2, className3, steplink, varText, step, ind }) {

    function clickOrderBtn() {
        if (varText.toLowerCase() === "checkout") {
            document?.querySelectorAll(".MuiButtonBase-root").forEach(each => {
                if (each.innerText.toUpperCase() === "PLACE ORDER") {
                    each.click()
                }
            })
        }
    }

    return (
        <>
            {varText && <div className={className1} style={{ marginLeft: -15 }}></div>}
            {varText &&
                <Link to={steplink} className="fc aic cartStep tdn hoverScale" onClick={clickOrderBtn}>
                    <div className={className2}>{ind + 1}</div>
                    <div
                        className={`cartStep__text ${step === ind + 1 ? "fw600" : ""}`}
                        onClick={clickOrderBtn}
                    >
                        {varText}
                    </div>
                </Link>
            }
            <div className={className3 || ""}></div>
        </>
    )
}
