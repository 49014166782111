import { Button } from '@mui/material'
import React from 'react'
import parseForm from '../../utils/parseForm'
import { useContext } from 'react'
import { Context } from '../../Context'
import { useLocation } from 'react-router-dom'
import { CART_SHIPPING } from '../../consts'
import Spinner from '../other/Spinner'


export default function CalculateShippingBtn({ isShippingCalculatedSet, isShippingCalculated, isValidZipCode, isLoading, addressError, zipCodeValue, firstName, lastName, phone }) {

	const { user, shipping, shippingSet, shippingServices, calculateShippingBtnClickedSet } = useContext(Context)
	const location = useLocation().pathname

	function calculateShipping(e) {
		e.preventDefault()
		const form = parseForm(e.target.closest("form"))
		shippingSet({ ...form })

		isShippingCalculatedSet(true) // !!
		calculateShippingBtnClickedSet(prev => prev + 1)

	}

	return (
		<>
			{zipCodeValue && firstName && lastName && phone && location === CART_SHIPPING && (
				<>
					<Spinner loading={false}>
						{!isShippingCalculated && isValidZipCode && !addressError.streetNumber && (
							<Button variant='contained' className='mt mb' onClick={calculateShipping}>
								Calculate Shipping
							</Button>
						)}
					</Spinner>
					{!isLoading &&
						<>
							{!isValidZipCode && <div className="danger tac mb">zip code / city / state is wrong</div>}
						</>
					}
				</>
			)}
			{shippingServices?.error && <div className="danger tac mb">{shippingServices.error}</div>}
			{addressError.streetNumber && <div className="danger tac my">you provided address without street number</div>}
		</>
	);
}
