import React from 'react'
import PostCards from '../post/PostCards'
import PostFilters from '../post/PostFilters'
import PostsOther from '../other/PostsOther'

export default function Articles() {
	return (
		<>
			{/* placeholder to fit CartReminder */}
			<div style={{ height: 65 }}></div>
			<PostFilters type="article" />
			<PostCards type="article" title="Articles" />
			<PostsOther type="article" title="Articles" className="mt4" />
		</>
	)
}
