import React, { useContext, useEffect } from "react"
import { Routes, useLocation } from "react-router-dom"
import { Context } from "../../Context"
import { CART_ROUTE, ARTICLES_ROUTE, FAQ_ROUTE, ABOUT_US_ROUTE, TERMS_ROUTE, PRIVACY_ROUTE, RETURNS_ROUTE, MAIN_ROUTE, PROFILE_ROUTE, PRODUCTS_ROUTE, USER_ORDER } from "../../consts"
import useNoUser from "../../hooks/useNoUser"
import useRoutes from "./useRoutes"

export default function AppRouter() {

	const { dialogSet } = useContext(Context)
	const location = useLocation().pathname
	const { noUserRedirect } = useNoUser()
	const { routes } = useRoutes()

	useEffect(() => {
		window.scrollTo(0, 0)
		location !== USER_ORDER && dialogSet({ dialogShow: false }) // hide dialog everewhere but USER_ORDER (dialog: "order is in queue..." must be seen)
		// visitor Allowed Locations: INSTANT NAVIGATE
		const visitorAllowedLocations = [MAIN_ROUTE, PRODUCTS_ROUTE, ARTICLES_ROUTE, PROFILE_ROUTE, CART_ROUTE, FAQ_ROUTE, ABOUT_US_ROUTE, TERMS_ROUTE, PRIVACY_ROUTE, RETURNS_ROUTE]
		if (!visitorAllowedLocations.includes(location)) {
			if (location.includes("/product/")) return // let visitor see products
			if (location.includes("/article/")) return // let visitor see articles
			noUserRedirect()
		}
	}, [location])

	return (
		<Routes>
			{routes.map((route, ind) => <React.Fragment key={ind}>{route}</React.Fragment>)}
		</Routes>
	)
}