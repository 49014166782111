import { UnarchiveOutlined } from "@mui/icons-material"
import { useContext } from "react"
import { Context } from "../../Context.js"

export default function ShippingBoxesUsed({ boxesUsed }) {

    const { user } = useContext(Context)

    return (
        user?.email === "enenotowitch@gmail.com" &&
        boxesUsed?.length > 0 &&
        <div className="fc wfc m0a">
            <div>Boxes used: {boxesUsed?.length}</div>
            <>
                {boxesUsed?.map(box => (
                    <div className="f aic">
                        <UnarchiveOutlined />
                        <span>{box.length}x{box.width}x{box.height} {box.totalWeight}g</span>
                    </div>
                ))}
            </>
        </div>
    )
}
