import React, { useContext, useEffect, useState } from "react"
import "./index.scss"
import { Context } from "../../Context"
import scrollToFilter from "../../utils/scrollToFilter"
import useWriteSearchParams from "../../hooks/useWriteSearchParams"
import { useLocation } from "react-router-dom"
import { ARTICLES_ROUTE } from "../../consts"

export default function SearchField({ className, isSearchFieldHoveredSet }) {

	const { skipProdsSet, showLoadMoreSet, searchFieldValue, searchFieldValueSet } = useContext(Context)

	const type = useLocation().pathname === ARTICLES_ROUTE ? "article" : "product"
	const { writeSearchParams } = useWriteSearchParams(type)
	const [focused, focusedSet] = useState(0)

	async function onChange(e) {
		searchFieldValueSet(e.target.value) // set input state
		skipProdsSet(0) // null skip to search from the start of the product list 
		showLoadMoreSet(true) // new filter => show LoadMore btn
		scrollToFilter()
	}

	useEffect(() => {
		// !!!!
		const currentSearchParams = JSON.parse(localStorage.getItem("filters"))

		const timeoutId = setTimeout(() => {
			writeSearchParams({ ...currentSearchParams, text: searchFieldValue })
		}, 500)

		return () => clearTimeout(timeoutId)
	}, [searchFieldValue, focused])

	return (
		<input
			value={searchFieldValue}
			className={`searchField ${className ? className : ""}`}
			onChange={onChange}
			placeholder={`search ${type} title & text`}
			// autoFocus
			onFocus={() => focusedSet(prev => prev + 1)}
			onMouseEnter={() => isSearchFieldHoveredSet(true)}
			onMouseLeave={() => isSearchFieldHoveredSet(false)}
		/>
	)
}
