import { useEffect, useState, useContext } from "react";
import { baseURL } from "../../consts";
import { Context } from "../../Context";

export default function useStripeWithoutShipping() {

    const [stripeLinkWithoutShipping, stripeLinkWithoutShippingSet] = useState("")
    const [isStripeLoading, isStripeLoadingSet] = useState(true)
    const { user } = useContext(Context)

    useEffect(() => {

        if (!user) return

        isStripeLoadingSet(true)
        fetch(`${baseURL}/create-checkout-session`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userId: user?._id, isWithShipping: false }),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.error) { // ! error
                    console.log(data.error)
                    return
                }
                // ! ok
                stripeLinkWithoutShippingSet(data.url);
                isStripeLoadingSet(false)
            });
    }, [user])

    return (
        { stripeLinkWithoutShipping, isStripeLoading }
    )
}