import { useEffect, useState, useContext } from "react";
import { baseURL } from "../../consts";
import { Context } from "../../Context";

export default function useStripe() {

	const [stripeLink, stripeLinkSet] = useState("")
	const [isStripeLoading, isStripeLoadingSet] = useState(true)
	const { user, shipping, shippingServices, shippingServicesSet, calculateShippingBtnClicked, shippingPriceSet } = useContext(Context)
	const [oneTime, oneTimeSet] = useState(0)
	const [boxesUsed, boxesUsedSet] = useState([])

	useEffect(() => {
		const { carrier, state, country, zipCode, city } = shipping
		// if (!carrier) return
		if (!state) return
		if (!country) return
		if (!zipCode) return
		if (!city) return

		isStripeLoadingSet(true)
		fetch(`${baseURL}/create-checkout-session`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userId: user?._id, shipping }),
			})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.error) { // ! error
					shippingServicesSet({ error: data.error })
					return
				}
				// ! ok
				stripeLinkSet(data.url);
				const shippingServicesArr = data?.getRatesData.length > 0 ? data?.getRatesData : []
				shippingServicesSet({ arr: shippingServicesArr })
				isStripeLoadingSet(false)
				boxesUsedSet(data?.boxes)
				// add shipmentCost to total price: (CartReminder) only on first load, then it's added in other place
				oneTime === 0 && shippingPriceSet(shippingServicesArr?.[0].shipmentCost)
				oneTimeSet(1)
			});
	}, [calculateShippingBtnClicked, shipping.shippingServiceSelected])

	return (
		{ stripeLink, isStripeLoading, boxesUsed }
	)
}