export const baseURL = process.env.REACT_APP_API_URL || "http://localhost:5000"
export const BRAND_COLOR = "#673BD9"
export const DANGER_COLOR = "violet"

// ROUTES
export const MAIN_ROUTE = "/"
export const PRODUCTS_ROUTE = "/products"
export const ARTICLES_ROUTE = "/articles"
export const SEARCH_ROUTE = "/search"
export const LIKED_PRODS_ROUTE = "/liked"
export const CART_ROUTE = "/cart"
export const CART_SHIPPING = "/cart/shipping"
export const PROFILE_ROUTE = "/profile"
export const ADD_PRODUCT = "/add/product"
export const ADD_ARTICLE = "/add/article"
export const EDIT_PRODUCT = "/edit/product/"
export const FULL_ROUTE = "/:type/:id"
export const ADMIN_ORDERS_NEW = "/admin/orders/new"
export const ADMIN_ORDERS_SENT = "/admin/orders/sent"
export const ADMIN_ORDERS_DIGITAL = "/admin/orders/digital"
export const ADMIN_ORDERS_CANCELED = "/admin/orders/canceled"
export const ADMIN_ORDER_NEW = "/admin/order/new"
export const ADMIN_ORDER_SENT = "/admin/order/sent"
export const ADMIN_ORDER_DIGITAL = "/admin/order/digital"
export const ADMIN_ORDER_CANCELED = "/admin/order/canceled"
export const ADMIN_ORDER_NEW_SHIPPING = "/admin/order/new/shipping"
export const ADMIN_ORDER_SENT_SHIPPING = "/admin/order/sent/shipping"
export const ADMIN_ORDER_NEW_TRACK = "/admin/order/new/track"
export const ADMIN_ORDER_SENT_TRACK = "/admin/order/sent/track"
export const USER_ORDERS = "/user/orders"
export const USER_ORDERS_NEW = "/user/orders/new"
export const USER_ORDERS_SENT = "/user/orders/sent"
export const USER_ORDERS_DIGITAL = "/user/orders/digital"
export const USER_ORDERS_CANCELED = "/user/orders/canceled"
export const USER_ORDER = "/user/order"
export const USER_ORDER_NEW = "/user/order/new"
export const USER_ORDER_SENT = "/user/order/sent"
export const USER_ORDER_DIGITAL = "/user/order/digital"
export const USER_ORDER_CANCELED = "/user/order/canceled"
export const USER_ORDER_SHIPPING = "/user/order/shipping"
export const USER_ORDER_TRACK = "/user/order/track"
export const FAQ_ROUTE = "/FAQ"
export const CONTACT_US_ROUTE = "/contact-us"
export const SUBSCRIBE_ROUTE = "/subscribe"
export const ABOUT_US_ROUTE = "/about-us"
export const TERMS_ROUTE = "/terms-and-conditions"
export const PRIVACY_ROUTE = "/privacy-policy"
export const RETURNS_ROUTE = "/return-policy"
export const HIDDEN_ARTICLES = "/hidden/articles"
export const HIDDEN_PRODUCTS = "/hidden/products"