import React, { useEffect } from "react"
import useShipping from "./useShipping"
import Input from "../form/Input"
import useUserOrOrderShipping from "./useUserOrOrderShipping"
import CartSteps from "./CartSteps"
import StripeBtn from "./StripeBtn"
import countriesWithISO from "../../utils/countriesWithISO"
import USAstatesWithISO from "../../utils/USAstatesWithISO"
import CalculateShippingBtn from "./CalculateShippingBtn"
import { useState } from "react"
import ShippingServiceSelect from "./ShippingServiceSelect"
import ShippingCarrierSelect from "./ShippingCarrierSelect"
import { useContext } from "react"
import { Context } from "../../Context"
import SelectNativeMui from "../form/SelectNativeMui"
import { useLocation } from "react-router-dom"
import { CART_SHIPPING } from "../../consts"
import InputWithValidation from "../form/InputWithValidation"
import ShippingPrice from "./ShippingPrice"
import ShippingAddressInput from "./ShippingAddressInput"
import useZipCodeValidator from "../../hooks/useZipCodeValidator"
import ShippingBoxesUsed from "./ShippingBoxesUsed"
import useAtLeastOneNotDigitalSaleProdFound from "./useAtLeastOneNotDigitalSaleProdFound"

export default function Shipping() {

	const { addShipping } = useShipping()
	let { userOrOrder, varText, varLink, isStripeLoading, userEmail, isInputDisabled, isBtnDisabled, varBtnHelpText, boxesUsed } = useUserOrOrderShipping()
	// calc shipping carrier
	const [isShippingCalculated, isShippingCalculatedSet] = useState(false)
	const { shippingSet, shippingServicesSet, shippingPriceSet, user } = useContext(Context)
	// validate zipCode
	const [cityValue, cityValueSet] = useState("")
	const [stateValue, stateValueSet] = useState("") // def val is space
	const [zipCodeValue, zipCodeValueSet] = useState("")
	const [countryValue, countryValueSet] = useState("")
	const [addressError, addressErrorSet] = useState({ streetNumber: false })
	// all Shipping Form values must be filled so calculateShippingBtn can be shown
	const [firstName, firstNameSet] = useState(userOrOrder?.firstName)
	const [lastName, lastNameSet] = useState(userOrOrder?.lastName)
	const [phone, phoneSet] = useState(userOrOrder?.phone)
	// define shippingType "normal" || "digital" => prevent adding digital-only orders to shipStation
	const atLeastOneNotDigitalSaleProdFound = useAtLeastOneNotDigitalSaleProdFound()

	function onSubmit(e) { // !! don't refactor
		addShipping(e) // 1. add shipping form to user (DB); then user.shipping is copied to order.shipping
		window.location.href = varLink // 2. USER: go to: stripeLink || ORDER: go to: "/order/sent"
	}

	function onChange(e) {
		// set Context shipping obj to pass to server to calc shipping there dep on shipping.service selected  
		const { name, value } = e.target
		shippingSet(prev => ({ ...prev, [name]: value }))
		if (name === "shippingServiceSelected") return // prevent hiding Select where user selects service 
		isShippingCalculatedSet(false) // smth changed in Shipping form show CalculateShippingBtn again / hide shippingServiceSelect
		shippingServicesSet(prev => ({ ...prev, error: "" })) // null shippingServices error on any form input change
	}

	// isBtnDisabled: CART_SHIPPING check if isShippingCalculated other locations check if isBtnDisabled
	const location = useLocation().pathname
	isBtnDisabled = location === CART_SHIPPING ? !isShippingCalculated : isBtnDisabled

	// validates city/state dep on zipCode, corrects city if not ok city came from google in ShippingAddressInput
	const { isValidZipCode, isLoading, okCity } = useZipCodeValidator({ zipCodeValue: zipCodeValue || userOrOrder?.zipCode, cityValue: cityValue || userOrOrder?.city, stateValue: stateValue || userOrOrder?.state, cityValueSet })

	return (
		<>
			<CartSteps step={2} />
			<section className="wM m0a shippingForm">
				<form onSubmit={onSubmit} onChange={onChange}>
					<Input editValue={userOrOrder?.email || userEmail} disabled name="email" type="email" label="email" isDisabled={isInputDisabled} helperText="registration email used" />
					<div className="f fwn g2">
						<Input editValue={userOrOrder?.firstName} required name="firstName" label="firstName" isDisabled={isInputDisabled} outerValueSet={firstNameSet} />
						<Input editValue={userOrOrder?.lastName} required name="lastName" label="lastName" isDisabled={isInputDisabled} outerValueSet={lastNameSet} />
					</div>

					<InputWithValidation editValue={userOrOrder?.phone} required name="phone" type="number" label="phone" isDisabled={isInputDisabled} pattern={"\\d{10,}"} title="Please enter at least 10 digits." className="mb" outerValueSet={phoneSet} />

					<ShippingAddressInput editValue={userOrOrder?.address} isInputDisabled={isInputDisabled} cityValueSet={cityValueSet} stateValueSet={stateValueSet} zipCodeValueSet={zipCodeValueSet} countryValueSet={countryValueSet} addressErrorSet={addressErrorSet} shippingPriceSet={shippingPriceSet} />
					{/* if city is not ok, show city Input to clarify city manually */}
					<Input className={`${!okCity ? "op0i poa b0 l0" : "op1 por"} cardAnim`} editValue={cityValue || userOrOrder?.city} value={cityValue} required name="city" label="clarify town/city" isDisabled={isInputDisabled} onChange={(e) => cityValueSet(e)} />

					<div className="f fwn g2 mb">
					</div>
					<div className="f fwn g2">
						<Input editValue={userOrOrder?.apartment} name="apartment" label="apartment (optional)" placeholder="Apartment, suite, unit etc." isDisabled={isInputDisabled} />
					</div>

					<Input editValue={userOrOrder?.customerNotes} name="customerNotes" variant="outlined" multiline label="order notes (optional)" placeholder="notes about your order, e.g special notes for delivery" className="mt" isDisabled={isInputDisabled} />

					{/* don't delete! hidden but needed: ShippingCarrierSelect & ShippingServiceSelect */}
					<div className="f fwn aic g2">
						{/* <ShippingCarrierSelect userOrOrder={userOrOrder} isShippingCalculatedSet={isShippingCalculatedSet} isDisabled={isInputDisabled} /> */}
						<ShippingServiceSelect userOrOrder={userOrOrder} isShippingCalculated={isShippingCalculated} isStripeLoading={isStripeLoading} isDisabled={isInputDisabled} />
					</div>

					{/* don't delete! hidden but needed */}
					<div className="op0 poa">
						<Input editValue={zipCodeValue || userOrOrder?.zipCode} value={zipCodeValue} required name="zipCode" type="number" label="postcode / ZIP" isDisabled={isInputDisabled} onChange={(e) => zipCodeValueSet(e)} />
						<SelectNativeMui editValue={userOrOrder?.country} value={countryValue} required name="country" arr={countriesWithISO} type="objArr" placeholder="country" isDisabled={isInputDisabled} />
						<Input editValue={stateValue || userOrOrder?.state} value={stateValue} required name="state" placeholder="state" isDisabled={isInputDisabled} onChange={(e) => stateValueSet(e)} />
					</div>
					<ShippingBoxesUsed boxesUsed={boxesUsed} />
					<ShippingPrice isStripeLoading={isStripeLoading} />
					<CalculateShippingBtn isShippingCalculatedSet={isShippingCalculatedSet} isShippingCalculated={isShippingCalculated} isValidZipCode={isValidZipCode} isLoading={isLoading} addressError={addressError} zipCodeValue={zipCodeValue || userOrOrder?.zipCode} firstName={firstName || userOrOrder?.firstName} lastName={lastName || userOrOrder?.lastName} phone={phone || userOrOrder?.phone} />
					<StripeBtn isBtnDisabled={isBtnDisabled} varText={varText} isStripeLoading={isStripeLoading} />
					<div className="brand tac fsi mt">{varBtnHelpText}</div>
					<input hidden name="shippingType" value={atLeastOneNotDigitalSaleProdFound ? "normal" : "digital"} />
				</form>
			</section>
		</>
	)
}
