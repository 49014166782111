import { useNavigate } from "react-router-dom"
import * as api from "../../api"
import { ABOUT_US_ROUTE, PRIVACY_ROUTE, RETURNS_ROUTE, TERMS_ROUTE } from "../../consts"
import useAddImg from "../addImg/useAddImg"

// type=about/terms/privacy/returns/...
export default function useEditFooter(type) {

	const navigate = useNavigate()
	const { imgArr } = useAddImg(`/upload/articleImages`) // load image to DB on paste

	async function editFooter(e) {
		e.preventDefault()

		await imgArr() // load image to DB on paste
		const textEditorValue = e.target.querySelector(".CodeMirror").innerText
		
		const res = await api.editFooter(type, textEditorValue)
		if (res.ok) {
			type === "about" && navigate(ABOUT_US_ROUTE)
			type === "terms" && navigate(TERMS_ROUTE)
			type === "privacy" && navigate(PRIVACY_ROUTE)
			type === "returns" && navigate(RETURNS_ROUTE)
		}
	}

	return (
		{ editFooter }
	)
}
