import parseFormTags from "./parseFormTags"
import parseTextEditorValue from "./parseTextEditorValue"

export default function parseFormAddProduct(e) { // e=event from onSubmit (html form)

	let form = {} // {"any field 1": "@","any field 2": "123","any field 3": "abc"}
	let characteristics = [] // gather product_add characteristics
	let oneCharacteristic = {}
	let informations = [] // gather product_add informations
	let oneInformation = {}
	let tags = []

	const custom_fields = [] // custom_fields = [fieldObj1{name, type,...}, fieldObj2{name, type,...}]
	let fieldObj = {}
	let options = [] // options = [optionObj1{name, price}, optionObj2{name, price}]
	let optionObj = {}

	let isDigitalSaleOnly

	// put all `inputs` values to "form"
	e.target.querySelectorAll("input, select, textarea").forEach(each => {

		if (each.name.includes("tags")) {
			tags = parseFormTags(each.value)
		}

		// !!!
		if (each.name && each.value) {
			// ! characteristics
			if (each.name.includes("characteristicName")) {
				oneCharacteristic.name = each.value
			}
			if (each.name.includes("characteristicValue")) {
				oneCharacteristic.value = each.value
				characteristics.push(oneCharacteristic)
				oneCharacteristic = {}
			}
			// ! informations
			if (each.name.includes("informationName")) {
				oneInformation.name = each.value
			}
			if (each.name.includes("informationValue")) {
				oneInformation.value = each.value
				informations.push(oneInformation)
				oneInformation = {}
			}

			// ! custom_fields
			for (let i = 1; i < 99; i++) {
				// ! name
				if (each.name.includes(`custom_field_name${i}`)) {
					fieldObj = { ...fieldObj, name: each.value }
				}
				// ? name
				// ! type
				if (each.name.includes(`custom_field_type${i}`)) {
					fieldObj = { ...fieldObj, type: each.value }
				}
				// ? type
				// ! options
				for (let i = 0; i < 99; i++) {
					if (each.name.includes(`option_name${i}`)) { // 1 option name
						optionObj = { ...optionObj, name: each.value }
					}
					if (each.name.includes(`option_price${i}`)) { // 1 option price
						optionObj = { ...optionObj, price: each.value }
						options[i] = optionObj
					}
					if (each.name.includes(`option_weight${i}`)) { // 1 option weight
						optionObj = { ...optionObj, weight: each.value }
						options[i] = optionObj
					}
				}
				fieldObj = { ...fieldObj, options: options }
				// ? options
				// ! imgSwitch
				if (each.name.includes(`custom_field_imgSwitch${i}`)) {
					fieldObj = { ...fieldObj, imgSwitch: each.value }
				}
				// ? imgSwitch
				// ! required
				if (each.name.includes(`custom_field_required${i}`)) {
					fieldObj = { ...fieldObj, required: each.value }
					custom_fields.push(fieldObj)
					options = []
				}
				// ? required
			}
			// ? custom_fields

			// ! when done types in DB can remove this
			if (each.name.includes("characteristicName")) return
			if (each.name.includes("characteristicValue")) return
			if (each.name.includes("informationName")) return
			if (each.name.includes("informationValue")) return
			if (each.name.includes("custom_")) return
			if (each.name.includes("option_")) return

			if (each.name === "isDigitalSaleOnly") {
				isDigitalSaleOnly = each.checked
			}

			const textEditorValue = parseTextEditorValue()

			form = { ...form, characteristics, informations, custom_fields, tags, desc: textEditorValue, [each.name]: each.value, isDigitalSaleOnly }
		}
	})

	return (
		form
	)
}