import PostAdd from "../post/PostAdd"
import PostCards from "../post/PostCards"
import PostFull from "../post/PostFull"
import VerifyLoginToken from "../auth/VerifyLoginToken"
import Profile from "../profile/Profile"
import Cart from "../cart/Cart"
import VerifyOrderToken from "../cart/VerifyOrderToken"
import OrderCards from "../order/OrderCards"
import Shipping from "../cart/Shipping"
import OrderSendEmailTrack from "../order/OrderSendEmailTrack"
import { ADMIN_ORDER_NEW, ADMIN_ORDERS_NEW, ADMIN_ORDERS_SENT, ADMIN_ORDER_NEW_SHIPPING, USER_ORDER, USER_ORDERS, ADMIN_ORDER_SENT, ADMIN_ORDER_SENT_SHIPPING, ADMIN_ORDER_NEW_TRACK, ADMIN_ORDER_SENT_TRACK, CART_ROUTE, USER_ORDER_SHIPPING, USER_ORDER_TRACK, ARTICLES_ROUTE, LIKED_PRODS_ROUTE, FAQ_ROUTE, CONTACT_US_ROUTE, SUBSCRIBE_ROUTE, ABOUT_US_ROUTE, TERMS_ROUTE, PRIVACY_ROUTE, RETURNS_ROUTE, HIDDEN_ARTICLES, HIDDEN_PRODUCTS, MAIN_ROUTE, CART_SHIPPING, USER_ORDERS_NEW, USER_ORDERS_SENT, USER_ORDER_NEW, USER_ORDER_SENT, PRODUCTS_ROUTE, ADD_PRODUCT, ADD_ARTICLE, PROFILE_ROUTE, USER_ORDERS_DIGITAL, USER_ORDER_DIGITAL, ADMIN_ORDERS_DIGITAL, ADMIN_ORDER_DIGITAL, USER_ORDERS_CANCELED, ADMIN_ORDERS_CANCELED, USER_ORDER_CANCELED, ADMIN_ORDER_CANCELED } from "../../consts"
import FAQPage from "../FAQ/FAQPage"
import MainPage from "../main/MainPage"
import Test from "./Test"
import ContactUs from "../contactUs/ContactUs"
import Subscribe from "../subscribe/Subscribe"
import EditFooter from "../footer/EditFooter"
import FooterBlock from "../footer/FooterBlock"
import LikedProducts from "../product/LikedProducts"
import UserOrders from "../order/UserOrders"
import Articles from "../article/Articles"
import Products from "../product/Products"
import PaymentCancel from "../other/PaymentCancel"

export default [
	// VISITOR ROUTES
	{
		path: "/verifyOrderToken/:token",
		element: <VerifyOrderToken />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	// Cart
	{
		path: CART_ROUTE,
		element: <Cart />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	// Auth
	{
		path: PROFILE_ROUTE,
		element: <Profile />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: "/verifyLoginToken/:token",
		element: <VerifyLoginToken />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	// Other
	{
		path: FAQ_ROUTE,
		element: <FAQPage />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: ABOUT_US_ROUTE,
		element: <FooterBlock type="about" />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: TERMS_ROUTE,
		element: <FooterBlock type="terms" />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: PRIVACY_ROUTE,
		element: <FooterBlock type="privacy" />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: RETURNS_ROUTE,
		element: <FooterBlock type="returns" />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	// Product
	{
		path: PRODUCTS_ROUTE,
		element: <Products />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: MAIN_ROUTE,
		element: <MainPage />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: "/product/:id",
		element: <PostFull type="product" />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	// Article
	{
		path: ARTICLES_ROUTE,
		element: <Articles />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: "/article/:id",
		element: <PostFull type="article" />,
		isPublic: true,
		isForUser: true,
		isForAdmin: true,
	},
	// USER ROUTES
	// Product
	{
		path: LIKED_PRODS_ROUTE,
		element: <LikedProducts />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	// Cart
	{
		path: CART_SHIPPING,
		element: <Shipping />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	// PaymentCancel
	{
		path: "/paymentCancel",
		element: <PaymentCancel />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	// User Orders
	{
		path: USER_ORDERS,
		element: <UserOrders />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDERS_NEW,
		element: <OrderCards title="New Orders" />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDERS_SENT,
		element: <OrderCards title="Sent Orders" />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDERS_DIGITAL,
		element: <OrderCards title="Digital Orders" />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDERS_CANCELED,
		element: <OrderCards title="Cancelled Orders" />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDER,
		element: <Cart />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDER_NEW,
		element: <Cart />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDER_SENT,
		element: <Cart />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDER_DIGITAL,
		element: <Cart />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDER_CANCELED,
		element: <Cart />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDER_SHIPPING,
		element: <Shipping />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: USER_ORDER_TRACK,
		element: <OrderSendEmailTrack />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	// Other
	{
		path: CONTACT_US_ROUTE,
		element: <ContactUs />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	{
		path: SUBSCRIBE_ROUTE,
		element: <Subscribe />,
		isPublic: false,
		isForUser: true,
		isForAdmin: true,
	},
	// ADMIN ROUTES
	// Other
	{
		path: "/edit/footer/:type",
		element: <EditFooter />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	// Product
	{
		path: ADD_PRODUCT,
		element: <PostAdd type="product" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: "/edit/product/:id",
		element: <PostAdd type="product" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	// Article
	{
		path: ADD_ARTICLE,
		element: <PostAdd type="article" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: "/edit/article/:id",
		element: <PostAdd type="article" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	// Hidden Posts
	{
		path: HIDDEN_PRODUCTS,
		element: <PostCards type="product" status="hidden" title="Hidden Products" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: HIDDEN_ARTICLES,
		element: <PostCards type="article" status="hidden" title="Hidden Articles" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	// Admin Order
	{
		path: ADMIN_ORDERS_NEW,
		element: <OrderCards title="Orders to deliver" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDERS_SENT,
		element: <OrderCards title="Delivered orders" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDERS_DIGITAL,
		element: <OrderCards title="Digital orders" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDERS_CANCELED,
		element: <OrderCards title="Cancelled orders" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDER_NEW,
		element: <Cart />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDER_DIGITAL,
		element: <Cart />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDER_CANCELED,
		element: <Cart />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDER_SENT,
		element: <Cart />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDER_NEW_SHIPPING,
		element: <Shipping />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDER_SENT_SHIPPING,
		element: <Shipping />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDER_NEW_TRACK,
		element: <OrderSendEmailTrack />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: ADMIN_ORDER_SENT_TRACK,
		element: <OrderSendEmailTrack />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	// Test
	{
		path: "/test",
		element: <Test type="product" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	},
	{
		path: "/testArticles",
		element: <Test type="article" />,
		isPublic: false,
		isForUser: false,
		isForAdmin: true,
	}
];
