import SelectNative from "../form/SelectNative"
import sortProductOptions from "./sortProductOptions"
import { useContext } from "react"
import { Context } from "../../Context"
import useCurrentSearchParams from "../../hooks/useCurrentSearchParams"
import useWriteSearchParams from "../../hooks/useWriteSearchParams"
import sortArticleOptions from "./sortArticleOptions"

export default function PostSort({ type, className }) {

	const { skipProdsSet, showLoadMoreSet, postSortValue, postSortValueSet } = useContext(Context)
	const { currentSearchParams } = useCurrentSearchParams()
	const { writeSearchParams } = useWriteSearchParams(type)

	function onChange(e) {
		postSortValueSet(e.target.value) // set Context value and pass it to SelectNative
		writeSearchParams({ ...currentSearchParams, sort: e.target.value })
		skipProdsSet(0) // null skip to sort from the start of the product list
		showLoadMoreSet(true) // new filter => show LoadMore btn
	}

	return (
		<div className={`f ${className ? className : ""}`}>
			<SelectNative
				value={postSortValue}
				arr={type === "product" ? sortProductOptions : sortArticleOptions}
				className="sort mla"
				onChange={onChange}
			/>
		</div>
	)
}
