import * as api from "../api"

export default function usePostMetaTags(type, id) {

    const res = async () => {
        const res = await api.getFullPostMetaTags(type, id)
        const { title, desc } = res
        // TODO has dup
        // title
        document.title = title;
        // description
        const description = document.querySelector('meta[name="description"]');
        description.setAttribute('content', desc);
        // fb
        const ogTitle = document.querySelector('meta[property="og:title"]');
        ogTitle.setAttribute('content', title);
        const ogDesc = document.querySelector('meta[property="og:description"]');
        ogDesc.setAttribute('content', desc);
        // twitter
        const twitterTitle = document.querySelector('meta[name="twitter:title"]');
        twitterTitle.setAttribute('content', title);
        const twitterDesc = document.querySelector('meta[name="twitter:description"]');
        twitterDesc.setAttribute('content', desc);
    }
    res()

    return (
        ""
    )
}
