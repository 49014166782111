import { useContext, useEffect } from "react"
import { Context } from "../../Context"
import SelectNativeMui from "../form/SelectNativeMui";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { CART_SHIPPING } from "../../consts";
import Input from "../form/Input"

export default function ShippingServiceSelect(props) {

	const { isShippingCalculated, isStripeLoading, isDisabled } = props
	const [value, valueSet] = useState("")
	const { shippingPriceSet, shippingServices, user } = useContext(Context)
	const location = useLocation().pathname

	function onChange(value) {
		valueSet(value)
		shippingServices?.arr?.map(({ serviceName, shipmentCost }) => { // add shipmentCost to `totalPrice`
			if (serviceName === value) {
				shippingPriceSet(shipmentCost)
			}
		})
	}

	// shippingServiceSelect `autofocus`
	useEffect(() => {
		document.querySelector("[name='shippingServiceSelected']")?.focus()
	}, [shippingServices])

	const showSelect = () => location === CART_SHIPPING && (isShippingCalculated && !shippingServices.error && !isStripeLoading)
	const editValue = () => location !== CART_SHIPPING && user?.shipping?.shippingServiceSelected

	return (
		<div className="mt2 w100">
			{showSelect() &&
				<SelectNativeMui
					disabled={isStripeLoading} // prevent changing shipmentCost while Stripe Loading
					required
					name="shippingServiceSelected"
					arr={shippingServices?.arr}
					type="objArr"
					placeholder="service"
					className="mb cardAnim"
					onChange={onChange}
					value={value}
					width="100%"
					{...props}
				/>
			}
			{editValue() && <Input editValue={editValue()} className="w50" isDisabled={isDisabled} />}
		</div>
	)
}
