export default [
	{
		title: "title 1",
		text: "text 1",
		id: 1
	},
	{
		title: "title 2",
		text: "text 2",
		id: 2
	},
	{
		title: "title 3",
		text: "text 3",
		id: 3
	},
	{
		title: "title 4",
		text: "text 4",
		id: 4
	},
	{
		title: "title 5",
		text: "text 5",
		id: 5
	},
	{
		title: "title 6",
		text: "text 6",
		id: 6
	},
	{
		title: "title 7",
		text: "text 7",
		id: 7
	},
	{
		title: "title 8",
		text: "text 8",
		id: 8
	},
	{
		title: "title 9",
		text: "text 9",
		id: 9
	},
	{
		title: "some title",
		text: "text 10",
		id: 10
	},
]