import { useContext } from "react"
import { Context } from "../../Context"
import { ADMIN_ORDERS_CANCELED, ADMIN_ORDERS_DIGITAL, ADMIN_ORDERS_NEW, ADMIN_ORDERS_SENT, USER_ORDERS_CANCELED, USER_ORDERS_DIGITAL, USER_ORDERS_NEW, USER_ORDERS_SENT } from "../../consts"
import useOrders from "../../hooks/useOrders"

export default function useOrderCards() {

	let { user } = useContext(Context)
	let { orders } = useOrders() // * orders are already divided to admin's/user's
	const role = user?.isAdmin ? "admin" : "user"

	// ! USER
	// show only this USER NEW orders
	if (window.location.pathname === USER_ORDERS_NEW) {
		// userId in DB ORDER matches this user id
		orders = orders?.filter(order => order.userId === user?._id && order.status === "pending")
	}

	// show only this USER SENT orders
	if (window.location.pathname === USER_ORDERS_SENT) {
		// userId in DB ORDER matches this user id
		orders = orders?.filter(order => order.userId === user?._id && order.status === "sent")
	}

	// show only this USER DIGITAL orders
	if (window.location.pathname === USER_ORDERS_DIGITAL) {
		// userId in DB ORDER matches this user id
		orders = orders?.filter(order => order.userId === user?._id && order.status === "digital download")
	}

	// show only this USER CANCELED orders
	if (window.location.pathname === USER_ORDERS_CANCELED) {
		// userId in DB ORDER matches this user id
		orders = orders?.filter(order => order.userId === user?._id && order.status === "cancelled")
	}

	// ! ADMIN
	// show only ADMIN NEW orders
	if (window.location.pathname === ADMIN_ORDERS_NEW) {
		orders = orders?.filter(order => order.status === "pending")
	}

	// show only ADMIN SENT orders
	if (window.location.pathname === ADMIN_ORDERS_SENT) {
		orders = orders?.filter(order => order.status === "sent")
	}

	// show only ADMIN DIGITAL orders
	if (window.location.pathname === ADMIN_ORDERS_DIGITAL) {
		orders = orders?.filter(order => order.status === "digital download")
	}

	// show only ADMIN CANCELED orders
	if (window.location.pathname === ADMIN_ORDERS_CANCELED) {
		orders = orders?.filter(order => order.status === "cancelled")
	}

	return { orders, role }
}
