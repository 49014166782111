export default function CartCardWeight({ obj }) {

    const { weight, quantity, custom_fields, isDigitalSaleOnly } = obj
    let optionsWeight = 0

    custom_fields?.map(custom_field => {
        if (typeof custom_field.value === "object") { // only objects have additional value (custom select)
            optionsWeight += Number(custom_field.value.weight)
        }
    })

    const totalWeight = (Number(weight) + Number(optionsWeight)) * quantity

    return (
        !isDigitalSaleOnly &&
        <div><span className="fw500">Weight:</span> {totalWeight}g</div>
    )
}
