import React, { useContext } from "react"
import "./index.scss"
import useCartSteps from "./useCartSteps"
import { Context } from "../../Context"
import CartStep from "./CartStep"
import useAtLeastOneNotDigitalSaleProdFound from "./useAtLeastOneNotDigitalSaleProdFound"
import { useLocation } from "react-router-dom"
import { CART_ROUTE } from "../../consts"

export default function CartSteps({ step }) {

	const { varText1, varText2, varText3, showSteps, steplink1, steplink2 } = useCartSteps()
	const { user } = useContext(Context)
	const hasProdsInCart = user?.cart?.length

	const stepsData = [
		{
			className1: "line line__small brandBg",
			steplink: steplink1,
			className2: "round fcc brandBg white",
			varText: varText1,
			step // comes from props
		},
		{
			className1: `line line__big ${step === 2 || step === 3 ? "brandBg" : ""}`,
			steplink: hasProdsInCart && steplink2,
			className2: `round fcc ${step === 2 || step === 3 ? "brandBg white" : ""}`,
			varText: varText2,
			step
		},
		{
			className1: `line line__big ${step === 3 ? "brandBg" : ""}`,
			steplink: "#",
			className2: `round fcc ${step === 3 ? "brandBg white" : ""}`,
			varText: varText3,
			step,
			className3: "line line__small",
		},
	]

	// if only digital prods in cart => render 2 steps
	const location = useLocation().pathname
	const atLeastOneNotDigitalSaleProdFound = useAtLeastOneNotDigitalSaleProdFound()
	location.includes(CART_ROUTE) && !atLeastOneNotDigitalSaleProdFound && stepsData.splice(1, 1)

	return (
		showSteps &&
		<div className="fcc g mb2">
			{stepsData.map((_, ind) => {
				return (
					<CartStep key={ind} {...stepsData[ind]} ind={ind} />
				)
			})}
		</div>
	)
}
