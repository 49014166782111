import React from 'react'
import Tags from '../tags/Tags';
import Product_article_card_top from "./product_article_card_top"
import Product_article_card_img from './Product_article_card_img';
import CardLink from './CardLink';
import TitleWithHint from '../other/TitleWithHint';

export default function Product_article_card({ obj, children, childrenBottom, className, imgClassName, topClassName, tagsClassName, tagsWrapClassName, titleClassName }) {

	const { title, tags, type } = obj

	return (
		<div className={`wS tdn card pb3 animation cardAnim ${className ? className : ""}`}>
			{/* top */}
			<Product_article_card_top obj={obj} className={topClassName} />
			{/* center */}
			<Product_article_card_img obj={obj} className={`hei220 w100 ${imgClassName}`} />
			<div className={tagsWrapClassName}>
				<Tags arr={tags} className={`scrollableArea hideScrollbar ${tagsClassName ? tagsClassName : ""}`} type={type} />
			</div>
			<CardLink obj={obj} className="">
				<TitleWithHint className={`title tac card__title ${titleClassName ? titleClassName : ""}`} title={title} />
				{/* bottom */}
				{children}
			</CardLink>
			{childrenBottom}
		</div>
	)
}
