import React from 'react'
import HeaderIcon from './HeaderIcon'
import ThemeSwitch from './ThemeSwitch'
import { CART_ROUTE, LIKED_PRODS_ROUTE, PROFILE_ROUTE, USER_ORDERS } from "../../consts"
import { FavoriteBorder, PersonOutline, ArticleOutlined, ShoppingCartOutlined, Inventory2Outlined } from "@mui/icons-material"
import UserOrdersIcon from "./UserOrdersIcon"

export default function HeaderIcons({ className }) {
    return (
        <div className={`mla headerIcons ${className}`}>
            <HeaderIcon text="theme"><ThemeSwitch /></HeaderIcon>
            <HeaderIcon text="products" route="products?text=&sort=createdAt%26desc"><Inventory2Outlined /></HeaderIcon>
            <HeaderIcon text="articles" route="articles?text=&sort=createdAt%26desc"><ArticleOutlined /></HeaderIcon>
            <HeaderIcon text="likes" field="likes" route={LIKED_PRODS_ROUTE}><FavoriteBorder /></HeaderIcon>
            <HeaderIcon text="cart" field="cart" route={CART_ROUTE}><ShoppingCartOutlined /></HeaderIcon>
            <HeaderIcon text="orders" route={USER_ORDERS}><UserOrdersIcon /></HeaderIcon>
            <HeaderIcon text="profile" route={PROFILE_ROUTE} className="profile"><PersonOutline /></HeaderIcon>
        </div>
    )
}
