import { Apps, Menu } from '@mui/icons-material';
import { useContext } from 'react';
import { Context } from '../../Context';

export default function CardsDesignSwitch({ showDesignSwitch }) {

    const { cardsDesignSet, cardsDesign } = useContext(Context)

    function changeDesign(design) {
        cardsDesignSet(design)
        localStorage.setItem("cardsDesign", design)
    }

    return (
        showDesignSwitch || showDesignSwitch === undefined &&
        <div className="por t4 l10 visibility930 svgh35 svgw35">
            <Apps onClick={() => changeDesign("cardsDesign2")} className={cardsDesign === "cardsDesign2" ? "brandi" : ""} />
            <Menu onClick={() => changeDesign("cardsDesign1")} className={cardsDesign === "cardsDesign1" ? "brandi" : ""} />
        </div>
    )
}
