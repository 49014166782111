import React, { useState, useEffect } from "react"
import "./index.scss"
import InputRequiredNativeTooltip from "../form/InputRequiredNativeTooltip"
import DeleteFile from "./DeleteFile";

export default function AddFile(props) {

	const { editValue, text, className, name, required, accept, productId, uploadedFilesSet } = props
	useEffect(() => { // edit mode: show uploadedName
		uploadedNameSet(editValue)
	}, [editValue])

	const [uploadedName, uploadedNameSet] = useState(editValue)

	function onChange(e) {
		uploadedNameSet(e.target.files[0]?.name)
		props.onChange && props.onChange(e.target.files[0])
	}

	const onDrop = (e) => {
		e.preventDefault();
		const files = Array.from(e.dataTransfer.files)
		uploadedNameSet(files[0]?.name)
		props.onChange && props.onChange(e.dataTransfer.files[0])
	}

	return (
		<div className={`${className} f fwn tac wbba w100`}>
			<label
				className="fcc fwni w100 h100 p por"
				onDrop={onDrop}
				onDragOver={(e) => e.preventDefault()}
			>
				<input
					name={name}
					type="file"
					className="hiddenInput"
					onChange={onChange}
					accept={accept}
				/>
				<InputRequiredNativeTooltip required={required} isImgUploaded={uploadedName} />
				{!uploadedName && <div>{text}</div>}
				{uploadedName && <div>{uploadedName}</div>}
			</label>
			<DeleteFile editValue={editValue} productId={productId} uploadedNameSet={uploadedNameSet} uploadedFilesSet={uploadedFilesSet} uploadedName={uploadedName} />
		</div>
	)
}
