export default function removePastedImgPathFromProductImgInDb(pastedOrDroppedImg, imgNamesPreparedToBeWrittenInDb) {

    pastedOrDroppedImg?.map(pastedImg => imgNamesPreparedToBeWrittenInDb?.map((uploadedImg, uploadedImgInd) => {
        if (uploadedImg.includes(pastedImg.name)) {
            imgNamesPreparedToBeWrittenInDb.splice(uploadedImgInd, 1)
        }
    }))

    return imgNamesPreparedToBeWrittenInDb
}
