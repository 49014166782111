import ViewsIcon from "../article/ViewsIcon";
import CardLink from "./CardLink";

export default function Article_card_bottom({ obj }) {

    const { views } = obj

    return (
        <>
            <ViewsIcon>{Number(views)}</ViewsIcon>
            <CardLink obj={obj}>
                <div className="brand fw600 hfsi">read ></div>
            </CardLink>
        </>
    )
}
