import React from 'react'
import { Visibility } from '@mui/icons-material'

export default function ViewsIcon({ children }) {
    return (
        <div className="f aic">
            <Visibility className="skipHoverSvg" />
            <span className="por l3">{children}</span>
        </div>
    )
}
