import React, { useContext } from 'react'
import Tabs from '../tabs/Tabs'
import FAQ from '../FAQ/FAQ'
import AddReview from '../product/AddReview'
import { Context } from '../../Context'

export default function Product_full_tabs({ characteristics, informations, productId }) {

	const tabsNamesArr = []
	const tabsValuesArr = []

	// ! characteristic
	if (characteristics?.length > 0) { // characteristics provided => render tab name & value
		tabsNamesArr.push("Characteristics")
		tabsValuesArr.push(characteristics?.map(({ name, value }, ind) => <div className="f fwn p"><div className="fw600 w100 pr3 tac fsi">{name}:</div><div className="w100 tac fsi">{value}</div></div>))
	}
	// ! information: FAQ
	if (informations?.length > 0) { // informations provided => render tab name & value
		tabsNamesArr.push("Information")
		tabsValuesArr.push(informations?.map(({ name, value }, ind) => <FAQ title={name} text={value} />))
	}

	// ! reviews
	// show tab "Reviews" if any characteristic or information provided OR if > 0 reviews
	const { reviews } = useContext(Context)
	const reviewCount = reviews?.filter(review => review.productId === productId && review).length
	let isVisibleReviews = characteristics.length === 0 && informations.length === 0 ? false : true
	isVisibleReviews = reviewCount > 0 ? true : isVisibleReviews

	tabsNamesArr.push("Reviews") // render tab "Reviews" anyway
	tabsValuesArr.push(<AddReview _id={productId} reviews={reviews} reviewCount={reviewCount} isVisibleReviews={isVisibleReviews} />)

	return (
		<Tabs arr={tabsNamesArr} className={`pb pt2 productTabs ${isVisibleReviews ? "" : "dn"}`}>
			{tabsValuesArr}
		</Tabs>
	)
}
