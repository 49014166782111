import { Context } from "../../Context"
import { useContext } from "react"
import { ADMIN_ORDER_NEW, ADMIN_ORDER_SENT, ADMIN_ORDER_NEW_SHIPPING, USER_ORDER, ADMIN_ORDER_SENT_SHIPPING, CART_ROUTE, USER_ORDER_SHIPPING, CART_SHIPPING, PROFILE_ROUTE, USER_ORDER_DIGITAL, ADMIN_ORDER_DIGITAL, USER_ORDER_CANCELED } from "../../consts"
import usePullPush from "../../hooks/usePullPush"
import useOneOrder from "../order/useOneOrder"
import useStripeWithoutShipping from "./useStripeWithoutShipping"
import useAtLeastOneNotDigitalSaleProdFound from "./useAtLeastOneNotDigitalSaleProdFound"

// `user` and (admin) `order` both have `cart` field (array of prod ids), so can be rendered in similar way
// = `user` and (admin) `order` = userOrOrder(object)
export default function useUserOrOrderCart() {

	const { user, dialogSet } = useContext(Context)
	const { pullPush } = usePullPush()
	const { stripeLinkWithoutShipping } = useStripeWithoutShipping(Context)
	const atLeastOneNotDigitalSaleProdFound = useAtLeastOneNotDigitalSaleProdFound()

	// get order from orderId
	const orderId = localStorage.getItem("orderId")
	const { order } = useOneOrder(orderId)

	let userOrOrder, varLink, varLink2, className, varText, varBtnText, varBtnText2, varBtnFn, varTitle, varTitle2, varBtnText3, varBtnFn3
	// ! user cart
	if (window.location.pathname.includes(CART_ROUTE)) {
		userOrOrder = user ? user : JSON.parse(localStorage.getItem("user")) // if no user use localStorage cart
		// ! if only digital-sale products are in the cart => render PLACE ORDER btn skipping CONTINUE TO SHIPPING btn
		if (atLeastOneNotDigitalSaleProdFound) {
			varLink = CART_SHIPPING
			varBtnText = "CONTINUE TO SHIPPING"
		} else {
			varLink = stripeLinkWithoutShipping
			varBtnText = "PLACE ORDER"
		}
		// ? if only digital-sale products are in the cart => render PLACE ORDER btn skipping CONTINUE TO SHIPPING btn
		varText = "* Additional taxes and fees will be calculated at checkout"
		// !!! no user when clicking PLACE ORDER btn => redirect to profile
		varBtnFn = () => !atLeastOneNotDigitalSaleProdFound && (window.location.href = PROFILE_ROUTE)
	}
	// !! ORDER
	const orderInfo = (userOrOrder, otherText) => (
		<>
			<div>Order id: <span className="fw600">{userOrOrder._id}</span></div>
			<br></br>
			<div>status: </div>
			<div><span className="fw600">{userOrOrder.status}</span></div>
			{otherText}
		</>
	)

	// ! admin order (NEW): one user order (of many users' orders) managed by admin
	if (window.location.pathname.includes(ADMIN_ORDER_NEW)) { // !!
		// order never changes after it's written to DB, so can be written to localStorage, to be displayed to admin
		userOrOrder = order
		varTitle = orderInfo(userOrOrder)
		varLink = ADMIN_ORDER_NEW_SHIPPING
		className = "hideSvg"
		varText = "Deliver these products using shipping info and send email with tracking link to user"
		varBtnText = "USER SHIPPING INFO"
	}
	// ! admin order (DIGITAL): one user order (of many users' orders) managed by admin
	if (window.location.pathname.includes(ADMIN_ORDER_DIGITAL)) { // !!
		// order never changes after it's written to DB, so can be written to localStorage, to be displayed to admin
		userOrOrder = order
		varTitle = orderInfo(userOrOrder)
		varLink = ""
		className = "hideSvg"
		varText = ""
		varBtnText = ""
	}
	// ! admin order (SENT): one user order (of many users' orders) managed by admin
	if (window.location.pathname.includes(ADMIN_ORDER_SENT)) { // !!
		// order never changes after it's written to DB, so can be written to localStorage, to be displayed to admin
		userOrOrder = order
		varTitle = orderInfo(userOrOrder)
		varLink = ADMIN_ORDER_SENT_SHIPPING
		className = "hideSvg"
		varText = "Check where the order was shipped"
		varBtnText = "SHIPPED TO"
	}
	// ! user order: one PREVIOUS user order managed by user
	if (window.location.pathname.includes(USER_ORDER)) { // !!
		// order never changes after it's written to DB, so can be written to localStorage, to be displayed to user
		userOrOrder = order
		varTitle = userOrOrder.status !== "digital download" ? orderInfo(userOrOrder) : varTitle = orderInfo(userOrOrder, <> <br></br> <div className="brand fw600">* Digital-only orders cannot be canceled.</div> </>)
		varTitle2 = userOrOrder.status !== "cancelled" ? `Thank you for your order!` : <div className="danger">This order is canceled!</div>
		varLink = CART_ROUTE
		// user clicks on orderCard (one prev order) => prev order info is written to localStorage(order)
		// then if user clicks REPEAT ORDER => prev order info is written to user.cart (DB), so user can pay and order same order again
		const repeatUserOrderCart = userOrOrder?.cart // [prodId1,prodId2...]
		varBtnFn = () => pullPush({ col: "user", field: "cart", item: repeatUserOrderCart, action: "push" })
		className = "hideSvg"
		varText = "Clicking \"Order again\" will add the products from your previous order to your cart. You can then modify this new order or proceed with the same order."
		varBtnText = "ORDER AGAIN"
		// user previous order has 2 buttons
		varLink2 = USER_ORDER_SHIPPING
		// digital orders have no SHIPPING INFO
		varBtnText2 = window.location.pathname !== USER_ORDER_DIGITAL || window.location.pathname !== ADMIN_ORDER_DIGITAL ? "CHECK SHIPPING INFO" : ""
		// digital/cancelled/sent orders have no CANCEL BUTTON
		varBtnText3 = (userOrOrder.status === "pending") ? "CANCEL ORDER" : ""
		varBtnFn3 = () => {
			dialogSet({
				dialogShow: true,
				dialogTitle: "Are you sure you want to cancel this order?",
				dialogContentName: "cancelOrder",
				dialogRightBtnFnParams: { _id: order._id }
			})
		}
	}

	// THIS IS DUP: for instant inscriptions change
	// ! user order: CANCELED
	if (window.location.pathname.includes(USER_ORDER_CANCELED)) {
		varTitle2 = <div className="danger">This order is canceled!</div>
		varBtnText3 = ""
	}

	return (
		{ userOrOrder, varLink, varLink2, className, varText, varBtnText, varBtnText2, varBtnFn, varTitle, varTitle2, varBtnText3, varBtnFn3 }
	)
}
