import React from "react"
import OrderCard from "./OrderCard"
import useOrderCards from "./useOrderCards"
import NoItems from "../other/NoItems"

export default function OrderCards({ title }) {

	const { orders, role } = useOrderCards()

	return (
		<>
			<div className="title tac mb">{title}</div>
			<div className="wfc m0a">
				{orders?.map(order => <OrderCard key={order._id} order={order} role={role} />)}
			</div>
			{orders.length === 0 && <NoItems />}
		</>
	)
}
