import React, { useState } from "react"
import Draggable from "./Draggable"
import PostCards from "../post/PostCards"
import { useContext } from "react"
import { Context } from "../../Context"

export default function PostsOther({ type, className }) {

	const { user, isVisibleBottomPartOfSite } = useContext(Context)
	const [postsLoadedNum, postsLoadedNumSet] = useState({ random: 0, viewed: 0 }) // prevent showing "No items matching these criteria" if there are no status posts

	return (
		isVisibleBottomPartOfSite &&
		<>
			{
				[
					{ title: "You may like", status: "random" },
					{ title: "Recently Viewed", status: "viewed" }
				].map(({ title, status }) => {
					if (!user && status === "viewed") return // hide "viewed" section from visitor
					return (
						<div className={`mb opAnim ${className ? className : ""} ${postsLoadedNum[status] === 0 ? "dn" : ""}`}>
							<div className="title tac">{title}</div>
							<Draggable>
								<div className="scrollableArea mob_pl">
									<PostCards type={type} status={status} className="f fwn jcfs" postsLoadedNumSet={postsLoadedNumSet} showDesignSwitch={false} ignoreDesign={true} />
								</div>
							</Draggable>
						</div>
					)
				})
			}
		</>
	)
}
