import React, { useState } from "react"
import parseTextEditorImg from "../textEditor/parseTextEditorImg"
import CardLink from "./CardLink"
import Product_article_card_img_img from "./Product_article_card_img_img";
import Product_article_card_img_img2 from "./Product_article_card_img_img2";

export default function Product_article_card_img({ obj, className }) {

	const { img, textEditorValue } = obj

	// img
	const textEditorImg = parseTextEditorImg(textEditorValue)
	const _img = textEditorImg || img?.[0] // in article_card rewrites DB img to textEditorImg
	// isImgLoaded
	const [isImgLoaded, isImgLoadedSet] = useState(false)
	// ! if prod img is hovered switch imgs
	const [isImgHovered, isImgHoveredSet] = useState(false)

	return (
		<CardLink obj={obj}>
			{!isImgHovered && <Product_article_card_img_img className={className} isImgLoaded={isImgLoaded} _img={_img} isImgHoveredSet={isImgHoveredSet} postType={obj?.type} />}
			{isImgHovered && <Product_article_card_img_img2 className={className} img={img} isImgHoveredSet={isImgHoveredSet} />}
			{/* using hidden img to define whether it's loaded */}
			<img src={_img} onLoad={() => isImgLoadedSet(true)} style={{ display: "none" }} />
		</CardLink>
	)
}
