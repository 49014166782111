import React from "react"
import Review from "./Review"

export default function Reviews({ _id: productId, reviews, reviewCount }) {
	return (
		<div className="mb">
			{reviews?.map(review => review.productId === productId && <Review key={review._id} obj={review} />)}
			{reviewCount === 0 && <div className="tac fsi mt">There are no Customers' Reviews yet, be the first to leave a review!</div>}
			<hr className="mt2 mb px mobile"></hr>
		</div>
	)
}
