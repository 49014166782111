import * as api from "../../api"
import { ADMIN_ORDER_NEW_TRACK, ADMIN_ORDER_SENT_TRACK } from "../../consts"
import parseForm from "../../utils/parseForm"

export default function useOrderSendEmailTrack() {

	// ! admin can resend email with ANOTHER TRACK, carrier, service, shipDate, notesToCustomer
	async function sendEmail(e) {
		if (!window.confirm("are you sure?")) return
		e.preventDefault()
		const form = parseForm(e)
		const res = await api.orderSendEmailTrack(form)
		if (res.ok) {
			await api.editPost({ type: "order", status: "sent", ...form })
			window.location.href = ADMIN_ORDER_SENT_TRACK // reload current page to see new updated info
		}
	}

	let varBtnText, showBtn, varBtnHelpText
	// ! admin order (new)
	if (window.location.pathname === ADMIN_ORDER_NEW_TRACK) {
		varBtnText = "SEND EMAIL"
		showBtn = true
	}
	// ! admin order (sent)
	if (window.location.pathname === ADMIN_ORDER_SENT_TRACK) {
		varBtnText = "SEND EMAIL AGAIN"
		varBtnHelpText = "* by clicking \"send email again\" button, admin can resend email with another track, carrier, service, shipDate, notesToCustomer. User will get an email with updated information!"
		showBtn = true
	}

	return (
		{ sendEmail, varBtnText, showBtn, varBtnHelpText }
	)
}
