const product = `${window.location.origin}/product/`
const tag = `${window.location.origin}/products?tag=`

export default [
	// HOW TO USE:
	/* 1. ONLY local files:
		- images must be placed in "./img" ("img" folder in this folder)
		- "src" & "src2": must equal image name + extension
		- "src" = image for desktop: SIZE: 1920 x 350
		- "src2" = image for mobile: SIZE: 768 x 500
		- link: add product id / tag name
	*/
	// ===
	// new banner
	{
		link: "",
		src: "banner.jpg",
		src2: "banner mobile.jpg",
	},
	// old examples
	// // 1
	// {
	// 	// ! here: PRODUCTid is added (653a3e05a5bd5249613bdf1a)
	// 	link: product + "65491b7e61753fd95b6a253f",
	// 	src: "halloween 1.png",
	// 	src2: "halloween 1 mobile.png",
	// },
	// // 2
	// {
	// 	// ! here: TAG name is added (halloween)
	// 	link: tag + "halloween",
	// 	src: "halloween 2.png",
	// 	src2: "halloween 2 mobile.png",
	// },
	// // 3
	// {
	// 	link: product + "65491b7e61753fd95b6a253f",
	// 	src: "t-shirt.png",
	// 	src2: "t-shirt mobile.png",
	// },
]