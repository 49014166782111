import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ADMIN_ORDER_CANCELED, ADMIN_ORDER_DIGITAL, ADMIN_ORDER_NEW, ADMIN_ORDER_SENT, ADMIN_ORDERS_CANCELED, ADMIN_ORDERS_DIGITAL, ADMIN_ORDERS_NEW, ADMIN_ORDERS_SENT, USER_ORDER, USER_ORDER_CANCELED, USER_ORDER_DIGITAL, USER_ORDER_NEW, USER_ORDER_SENT, USER_ORDERS, USER_ORDERS_CANCELED, USER_ORDERS_DIGITAL, USER_ORDERS_NEW, USER_ORDERS_SENT } from "../../consts"
import "./index.scss"
import cleanTimestamp from "../../utils/cleanTimestamp"

export default function OrderCard({ order, role }) {

	const navigate = useNavigate()
	const location = useLocation().pathname
	let { _id, createdAt, status, number, isChecked, isCheckedByAdmin } = order
	isChecked = role === "admin" ? isCheckedByAdmin : isChecked

	function onClick(order) {
		// order never changes after it's written to DB(it's paid), so can be written to localStorage, to be displayed to admin/user
		localStorage.setItem("orderId", _id)
		location === ADMIN_ORDERS_NEW && navigate(ADMIN_ORDER_NEW)
		location === ADMIN_ORDERS_SENT && navigate(ADMIN_ORDER_SENT)
		location === ADMIN_ORDERS_DIGITAL && navigate(ADMIN_ORDER_DIGITAL)
		location === ADMIN_ORDERS_CANCELED && navigate(ADMIN_ORDER_CANCELED)
		location === USER_ORDERS_NEW && navigate(USER_ORDER_NEW)
		location === USER_ORDERS_SENT && navigate(USER_ORDER_SENT)
		location === USER_ORDERS_DIGITAL && navigate(USER_ORDER_DIGITAL)
		location === USER_ORDERS_CANCELED && navigate(USER_ORDER_CANCELED)
		location === USER_ORDERS && navigate(USER_ORDER)
	}

	function orderCard({ condition, title, displayValue }) {
		return (
			(condition || condition === undefined) &&
			<div className="fc p orderCard__item">
				<div className="fw500">{title}</div>
				<div className="brand">{displayValue}</div>
			</div>
		)
	}

	return (
		<section className={`f jcsb mb mx wM orderCard cardAnim ${!isChecked ? "brandBgLight" : ""}`} onClick={() => onClick(order)}>
			{/* number */}
			{orderCard({
				condition: location.includes("admin"),
				title: "No.",
				displayValue: number
			})}
			{/* id */}
			{orderCard({
				title: "Order id:",
				displayValue: _id
			})}
			{/* created */}
			{orderCard({
				title: "Created:",
				displayValue: cleanTimestamp(createdAt)
			})}
			{/* order status */}
			{orderCard({
				condition: status,
				title: "Order status:",
				displayValue: status
			})}
		</section>
	)
}
