import IsInCartIcon from "../product/IsInCartIcon"
import RatingCount from "../product/RatingCount"
import Product_article_card_design1_bigText from "./Product_article_card_design1_bigText"
import Product_card_bottom from "./Product_card_bottom"
import Product_article_card from "./product_article_card"

export default function Product_card_design1({ obj, design1Classes }) {

    const { brand, _id, desc } = obj

    return (
        <div className="por m0a">
            <Product_article_card
                obj={obj}
                {...design1Classes}
            >

                {/* product & article cards are 70% same; product_card diff here */}
                <div>
                    <div className="gray tac card__brand">{brand}</div>
                    <RatingCount _id={_id} />
                    <Product_article_card_design1_bigText text={desc} />
                </div>

            </Product_article_card>

            <div className="fcc jcsb aife wid545 poa hei0 b27 r15">
                <Product_card_bottom obj={obj} />
            </div>
        </div>
    )
}
