import { useEffect, useState } from "react";
import LoadingBar from "../other/LoadingBar";

export default function Product_article_card_img_img2({ img, isImgHoveredSet, className }) {

    const [imgState, imgStateSet] = useState(img?.[0])
    const [isImgsEnded, isImgsEndedSet] = useState(false)

    useEffect(() => {
        for (let i = 0; i < img.length; i++) {
            setTimeout(function () {
                imgStateSet(img?.[i]) // switch imgs

                // go to 1 img
                if (i === img.length - 1) {
                    setTimeout(() => {
                        isImgsEndedSet(true)
                        imgStateSet(img?.[0])
                    }, 1000);
                }
                // go to 1 img
            }, 1000 * i);
        }
    }, [])

    return (
        <>
            <img className={className} src={imgState} onMouseLeave={() => isImgHoveredSet(false)} />
            {img.length > 1 && <LoadingBar imgState={imgState} isImgsEnded={isImgsEnded} />}
        </>
    )
}
