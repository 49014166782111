import React from "react"
import cleanTimestamp from "../../utils/cleanTimestamp"
import Tags from "../tags/Tags"
import Markdown from "../textEditor/Markdown"
import Product_article_card_top from "./product_article_card_top"
import timeRead from "../../utils/timeRead";
import ViewsIcon from "../article/ViewsIcon";

export default function Article_full({ obj }) {

	const { title, _id: articleId, createdAt, tags, textEditorValue, views, type } = obj.fullPost
	const { hidePost } = obj

	return (
		<div className="fc aic">
			<Product_article_card_top obj={{ ...obj.fullPost, hidePost }} className="w100">
				<ViewsIcon>{Number(views) + 1}</ViewsIcon>
			</Product_article_card_top>

			<div className="title tac mt">{title}</div>
			<div className="gray mb">{cleanTimestamp(createdAt, false)} | {timeRead(textEditorValue)}</div>
			<Tags arr={tags} type={type} />
			<Markdown>{textEditorValue}</Markdown>
		</div>
	)
}
