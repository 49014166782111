import { useLocation } from "react-router-dom"
import { CART_ROUTE } from "../../consts"

export default function useSubtotal({ userOrOrder, allProductsTotalPrice }) {

	let subtotal = 0
	const location = useLocation().pathname

	if (location === CART_ROUTE) { // don't add shipment price in CART
		subtotal = allProductsTotalPrice.toFixed(2)
	} else { // add shipment price in ORDER made
		subtotal = (allProductsTotalPrice + userOrOrder?.shipment?.price).toFixed(2)
	}

	return { subtotal }
}
