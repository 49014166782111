import React, { useState } from "react"
import "./index.scss"

// can be used separately and as Tabs part
export default function FAQ({ title, text, className, isWrapClickable = true }) {

	const [on, onSet] = useState(false)

	function onClick(e, place) {
		e.stopPropagation()
		// sometimes we need to copy text from FAQ wrap, so prevent toggling FAQ, when we try to copy text
		if (!isWrapClickable && place === "wrap") return
		onSet(prev => !prev)
	}

	return (
		title &&
		<div className={`FAQ opAnim ${className}`} onClick={(e) => onClick(e, "wrap")}>
			<div className={`FAQ__title ${on ? "brand" : ""}`} onClick={(e) => onClick(e, "title")}>
				{title}
				<span className="FAQ__toggle" onClick={(e) => onClick(e, "toggle")} >{on ? "—" : "+"}</span>
			</div>
			{on && <pre className="FAQ__text">{text}</pre>}
		</div>
	)
}