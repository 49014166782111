import React from "react"
import Article_card_design1 from "./Article_card_design1"
import Article_card_design2 from "./Article_card_design2"

export default function article_card({ obj, cardsDesign, design1Classes }) {
	return (
		cardsDesign === "cardsDesign1"
			?
			<Article_card_design1 obj={obj} design1Classes={design1Classes} />
			:
			<Article_card_design2 obj={obj} />
	)
}
