import { useContext, useEffect } from "react"
import { Context } from "../Context"

export default function useClearContextUploadedImg() {

    const { uploadedImgSet, pastedOrDroppedImgSet } = useContext(Context)

    // clear all old Context imgs before adding new post
    useEffect(() => {
        uploadedImgSet([])
        pastedOrDroppedImgSet([])
    }, [])
}
