import React, { useContext } from "react"
import Product_card from "../view/product_card"
import Article_card from "../view/article_card"
import Skeleton from "../other/Skeleton"
import { Context } from "../../Context"

export default function PostCard({ post, loading, ignoreDesign }) {

	// ! cardsDesign
	const { cardsDesign } = useContext(Context)
	const design1Classes = {
		className: "por f miw900 m0a pb0 hei255", // card
		imgClassName: "hei255 maw330 brL btrr0 bbrr0",
		topClassName: "poa r0 wid100 fdrr jcfs g08",
		tagsClassName: "",
		tagsWrapClassName: "poa zi1 b0 r160 wid350 hei47",
		titleClassName: "por l0 t0 wid470",
	};
	// ? cardsDesign

	return (
		post?.type === "product"
			?
			<Skeleton loading={loading}>
				<Product_card obj={{ ...post }} cardsDesign={!ignoreDesign ? cardsDesign : "cardsDesign2"} design1Classes={design1Classes} />
			</Skeleton>
			:
			<Skeleton loading={loading}>
				<Article_card obj={{ ...post }} cardsDesign={!ignoreDesign ? cardsDesign : "cardsDesign2"} design1Classes={design1Classes} />
			</Skeleton>
	)
}
