import React from "react"
import useCurrentSearchParams from "../../hooks/useCurrentSearchParams"
import Tag from "../tags/Tag"
import useWriteSearchParams from "../../hooks/useWriteSearchParams"
import { useContext } from "react"
import { Context } from "../../Context"
import fixSortText from "./fixSortText"

export default function PostFiltersTags({ type }) {

	const { currentSearchParams } = useCurrentSearchParams()
	const { writeSearchParams } = useWriteSearchParams(type)
	const { searchFieldValueSet, postSortValueSet, skipProdsSet } = useContext(Context)

	function deleteTag(paramName) {
		delete currentSearchParams?.[paramName] // ??
		writeSearchParams(currentSearchParams)
		paramName === "text" && searchFieldValueSet("") // null searchFieldValue
		paramName === "sort" && postSortValueSet("")
		skipProdsSet(0)
	}

	return (
		<div className="fcc w100">
			{Object.keys(currentSearchParams)?.map((paramName, paramInd) => {
				if (paramName === "time") return // don't render time
				let paramValue = Object.values(currentSearchParams)[paramInd]
				paramValue = fixSortText(paramName, paramValue)
				if (!paramValue) return

				{/* don't render Tag: "Items: Newest" */ }
				return (
					<Tag tag={`${paramName}: ${paramValue}`} onDelete={() => deleteTag(paramName)} className={`cardAnim ${paramValue === "Items: Newest" ? "dn" : ""}`} />
				)
			})}
		</div>
	)
}
