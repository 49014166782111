import { useEffect } from "react"
import * as api from "../../api"
import { ARTICLES_ROUTE, MAIN_ROUTE } from "../../consts";

export default function Test({ type }) { // TODO delete

	useEffect(() => {
		if (!window.confirm("Are you sure? Test will add 100 test posts!")) return
		type === "product" ? api.test() : api.testArticles()
		setTimeout(() => {
			window.location.href = type === "product" ? MAIN_ROUTE : ARTICLES_ROUTE
		}, 5000);
	}, [])

	return (
		`test ${type}: redirecting in 5 seconds`
	)
}
