import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext } from 'react';
import { Context } from '../../Context';
import useDialog from './useDialog';
import './index.scss'
import { Close } from '@mui/icons-material';
import { BRAND_COLOR } from '../../consts';

export default function _Dialog() {

	const { dialog, dialogSet, theme } = useContext(Context)
	// * gray cause of eval
	const { share, addedToCart, ordered, goToCart, deletePost, hidePost, unHidePost, cancelOrder } = useDialog()

	const isWhiteSpaceNoWrap = dialog?.dialogImg ? "wsn" : "" // if dialogImg exists => DialogTitle is 1 line, else DialogTitle is multiline

	return (
		<Dialog
			open={dialog?.dialogShow}
			onClose={() => dialogSet({ dialogShow: false })}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className={`fcc blurAnim ${theme === "dark" ? "darkDialog" : ""}`}
		>
			<Close className='mobile fcc poa r0 brand' style={{ height: 40, width: 40, fill: BRAND_COLOR }} onClick={() => dialogSet({ dialogShow: false })} />
			<DialogTitle id="alert-dialog-title" className={`tac ${isWhiteSpaceNoWrap} oh toe fz24 brandi`}>
				{dialog?.dialogTitle}
			</DialogTitle>
			{dialog?.dialogImg && <img src={dialog?.dialogImg} className="w100" />}
			<DialogContent className="fcc">
				{/* eg: 						  share() */}
				{dialog?.dialogContentName && eval(`${dialog?.dialogContentName}()`)}
			</DialogContent>
			{dialog?.dialogHasButtons &&
				<DialogActions className="mb">
					<Button variant="outlined" onClick={() => dialogSet({ dialogShow: false })}>{dialog?.dialogLeftBtnText}</Button>
					<Button variant="contained" onClick={eval(dialog?.dialogRightBtnFn)} autoFocus>{dialog?.dialogRightBtnText}</Button>
				</DialogActions>
			}
		</Dialog>
	)
}