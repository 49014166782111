import { useContext } from "react"
import { Context } from "../../Context"

export default function useAtLeastOneNotDigitalSaleProdFound() {

    const { user } = useContext(Context)
    const userCart = user?.cart || JSON.parse(localStorage.getItem("user"))?.cart
    const atLeastOneNotDigitalSaleProdFound = userCart?.find(prod => prod.isDigitalSaleOnly === false || prod.isDigitalSaleOnly === "false")

    return atLeastOneNotDigitalSaleProdFound
}
