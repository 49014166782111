import React from 'react'
import PostCards from "../post/PostCards";
import PostFilters from "../post/PostFilters";
import PostsOther from "../other/PostsOther";
import Categories from "../categories/Categories";
import Banner from '../banner/Banner';

export default function Products() {
	return (
		<>
			{/* <Banner /> */}
			<Categories />
			<PostFilters type="product" />
			<PostCards type="product" title="Products" />
			<PostsOther type="product" className="mt4" />
		</>
	)
}
