import React from 'react'

export default function SelectOptions({ type, arr }) {

	function options(objOption) {
		// Shipping: country
		if (objOption?.name) { // !! CARRIER CAN BRAKE
			return <option value={objOption.name}>
				{objOption.name}
			</option>
		}
		// Shipping: ShippingServiceSelect
		if (objOption?.serviceName) {
			return <option value={objOption.serviceName}>
				(${objOption.shipmentCost.toFixed(2)}) {objOption.serviceName}
			</option>
		}
	}

	return (
		<>
			{type === "strArr" && arr?.map(option => <option value={option}>{option}</option>)}
			{type === "objArr" && arr?.map(objOption => {
				if (!objOption) return
				return (
					options(objOption)
				)
			})}
		</>
	)
}
