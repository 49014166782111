import { createSearchParams, useNavigate } from "react-router-dom"
import { ARTICLES_ROUTE, PRODUCTS_ROUTE } from "../consts";

export default function useWriteSearchParams(type) {

	const navigate = useNavigate()

	function writeSearchParams(objToWrite) { // eg: currentSearchParams
		const now = Math.floor(Date.now() / 1000)
		let searchParams = { ...objToWrite, time: now }
		// ! on 1 load: sort = undefined => add sort: "createdAt&desc" to prev dup posts
		searchParams = !searchParams.sort ? { ...searchParams, sort: "createdAt&desc" } : searchParams
		const lastSearched = objToWrite.time
		const timePassed = now - lastSearched
		// if more than 10 mins passed erase searchParams
		if (timePassed > 600) {
			searchParams = { time: now }
		}
		navigate({
			pathname: type === "product" ? PRODUCTS_ROUTE : ARTICLES_ROUTE,
			search: createSearchParams(searchParams).toString()
		});
		localStorage.setItem("filters", JSON.stringify(searchParams)) // memo search params
	}

	return (
		{ writeSearchParams }
	)
}
