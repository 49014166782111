import React, { useEffect } from "react"
import "./index.scss"
import usePostsFilter from "../../hooks/usePostsFilter"
import usePostsRandom from "../../hooks/usePostsRandom"
import PostCard from "./PostCard"
import usePostsLiked from "../../hooks/usePostsLiked"
import usePostsViewed from "../../hooks/usePostsViewed"
import usePostsHidden from "../../hooks/usePostsHidden"
import LoadMore from "./LoadMore"
import NoItems from "../other/NoItems"
import CardsDesignSwitch from "../other/CardsDesignSwitch"
import useUserOrFakeUser from "../../hooks/useUserOrFakeUser"

// type=product/article/...
// status=all/liked/...
export default function PostCards({ type, status = "filtered", title, className, postsLoadedNumSet, showDesignSwitch, ignoreDesign = false }) {

	// * gray cause of eval
	const [liked, loading1, skipSet1, serverPostsNum1] = usePostsLiked(type) // all products/all articles/...
	const [filtered, loading2, skipSet2, serverPostsNum2] = usePostsFilter(type) // products/articles/... filtered (on load) with pagination
	const [random, loading3, postsLoadedNum3] = usePostsRandom(type)
	const [viewed, loading4, postsLoadedNum4] = usePostsViewed(type)
	const [hidden, loading5, skipSet5, serverPostsNum5] = usePostsHidden(type)

	const user = useUserOrFakeUser()

	useEffect(() => {
		postsLoadedNumSet && postsLoadedNumSet({ random: postsLoadedNum3, viewed: postsLoadedNum4 })
	}, [postsLoadedNum3, postsLoadedNum4])

	return (
		<div className="mb">
			<div className="fcc m0a mb title">
				<div>{title}</div>
				<CardsDesignSwitch showDesignSwitch={showDesignSwitch} />
			</div>
			<div className={`f g m0a cards ${className ? className : ""}`}>

				{eval(status)?.map(post => { // post=product/article/...
					user?.cart?.map(cartProduct => cartProduct?._id === post?._id && (post.isInCart = true))
					return <PostCard key={post?._id} post={post} loading={false} ignoreDesign={ignoreDesign} />
				})}

				{!eval(status)?.length && <NoItems />}
			</div>
			<LoadMore skips={{ skipSet1, skipSet2, skipSet5 }} serverPostsNum={{ serverPostsNum1, serverPostsNum2, serverPostsNum5 }} loadings={{ loading1, loading2, loading5 }} status={status} />
		</div>
	)
}
