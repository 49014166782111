import { useState } from "react"
import { useLocation } from "react-router-dom"
import { ARTICLES_ROUTE, LIKED_PRODS_ROUTE, PRODUCTS_ROUTE } from "../consts"

export default function useShowBottomPartOfSite() {

    // ("you may like", "recently viewed", "footer")
    const [showBottomPartOfSite, showBottomPartOfSiteSet] = useState(false)
    const location = useLocation().pathname

    const locationsWhereBottomPartOfSiteIsShownAfterAllPostsLoaded = [PRODUCTS_ROUTE, ARTICLES_ROUTE, LIKED_PRODS_ROUTE]

    let isVisibleBottomPartOfSite
    if (!locationsWhereBottomPartOfSiteIsShownAfterAllPostsLoaded.includes(location)) {
        isVisibleBottomPartOfSite = true
    } else {
        isVisibleBottomPartOfSite = showBottomPartOfSite
    }

    return { showBottomPartOfSite, showBottomPartOfSiteSet, isVisibleBottomPartOfSite }
}
