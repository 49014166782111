import { LayersOutlined } from "@mui/icons-material"
import useNotCheckedOrders from "../order/useNotCheckedOrders"
import { useContext } from "react"
import { Context } from "../../Context"

export default function UserOrdersIcon() {

	const { user } = useContext(Context)
	const { notCheckedSent, notCheckedDigital } = useNotCheckedOrders("user", user?._id)
	const iconCount = notCheckedSent || notCheckedDigital

	return (
		<>
			<LayersOutlined />
			{iconCount > 0 && <div className="iconCount">{iconCount}</div>}
		</>
	)
}
