import { useContext } from "react"
import { Context } from "../../Context"

export default function ShippingPrice({ isStripeLoading }) {

    const { calculateShippingBtnClicked, shippingPrice } = useContext(Context)

    return (
        (calculateShippingBtnClicked && shippingPrice && !isStripeLoading) ?
            <div className="brand wfc m0a mt">shipping price: ${shippingPrice.toFixed(2)}</div>
            :
            null
    )
}
