import React, { useContext } from "react"
import { Context } from "../../Context"
import Tabs from "../tabs/Tabs"
import PostSort from "./PostSort"
import scrollToFilter from "../../utils/scrollToFilter"
import { ARTICLES_ROUTE, PRODUCTS_ROUTE } from "../../consts"
import PostFiltersTags from "./PostFiltersTags"
import useCurrentSearchParams from "../../hooks/useCurrentSearchParams"
import useWriteSearchParams from "../../hooks/useWriteSearchParams"
import SearchIcon from "../search/SearchIcon"
import { useLocation } from "react-router-dom"
import SelectNative from "../form/SelectNative"

export default function PostFilters({ type }) {

	const { skipProdsSet, showLoadMoreSet } = useContext(Context)
	const { currentSearchParams } = useCurrentSearchParams()
	const { writeSearchParams } = useWriteSearchParams(type)
	const location = useLocation().pathname

	function onClick(tagClicked) {
		writeSearchParams({ ...currentSearchParams, tag: tagClicked })
		skipProdsSet(0) // null skip to filter from the start of the product list
		showLoadMoreSet(true) // new filter => show LoadMore btn
		scrollToFilter()
	}

	const articlesStyles = location === ARTICLES_ROUTE && {
		"filter": "op0 pen",
		"search": "mla",
		"sort": "mla",
	}

	return (
		<div className={`postFilters wfc m0a ${articlesStyles.postFilters || ""}`}>
			{/* filter */}
			<Tabs
				arr={["NEW", "FEATURED", "HOT", "SALE"]}
				className={`filter ${articlesStyles.filter || ""}`}
				onClick={(tagClicked) => onClick(tagClicked)}
				colorTab={currentSearchParams.tag}
			/>
			{/* search */}
			<SearchIcon className={`${articlesStyles.search || ""}`} />
			{/* sort */}
			<PostSort type={type} className={`${articlesStyles.sort || ""}`} />
			{/* all categories */}
			{location === PRODUCTS_ROUTE &&
				<SelectNative
					defaultValue="ALL CATEGORIES"
					arr={[]}
					className="mobile sort" // copy style from sort
					onClick={() => document.querySelector(".burger__btn").click()}
				/>
			}
			{/* tags */}
			<PostFiltersTags type={type} />
		</div>
	)
}
