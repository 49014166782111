export default function Product_article_card_design1_bigText({ text }) {

    let fixedText = text?.replace(/!\[image\].+png\)/g, "") // remove images
    const creativeCommons = /\*\*This design is protected by Creative Commons \(4\.0 International License\)([\s\S]*?)https:\/\/creativecommons\.org\/licenses\/by-nc-nd\/4\.0\//;
    fixedText = fixedText.replace(creativeCommons, "")
    fixedText = fixedText.replace(/#/g, "")
    fixedText = fixedText.replace(/\*/g, "")

    return (
        <div className="tac pl pr oh hei109 wid470" style={{ paddingTop: 12 }}>{fixedText}</div>
    )
}
